// import {API_URL} from "@env";
import axios from 'axios';

const API_URL = 'https://production.yourgold.app';

export const getEndpoint = (token, url, params = {}) => {
  var config = {
    method: 'get',
    url: `${API_URL}${url}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params: params,
  };
  return axios(config);
};

export const saveEndpoint = (token, url, body) => {
  var config = {
    method: 'post',
    url: `${API_URL}${url}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: body,
  };
  return axios(config);
};

export const updateEndpoint = (token, url, id = null, body) => {
  var config = {
    method: 'put',
    url: `${API_URL}${url}/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: body,
  };
  return axios(config);
};

export const updateWithoutIdEndpoint = (token, url, body) => {
  const config = {
    method: 'put',
    url: `${API_URL}${url}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: body,
  };
  return axios(config);
};

export const postEndpointWithoutToken = (url, body) => {
  var config = {
    method: 'post',
    url: `${API_URL}${url}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  };
  return axios(config);
};

export const putEndpointWithoutToken = (url, body) => {
  var config = {
    method: 'put',
    url: `${API_URL}${url}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  };
  return axios(config);
};

export const updatePodcastEndpoint = (token, url, id, body) => {
  var config = {
    method: 'put',
    url: `${API_URL}${url}/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: body,
  };
  return axios(config);
};

export const fetchPodcastData = (token, query) => {
  var config = {
    method: 'get',
    url: `${API_URL}/agency/fetch-rss-feed?feedUrl=${query}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const getUserTypeListEndpoint = (token, id) => {
  var config = {
    method: 'get',
    url: `${API_URL}/admin/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

export const addUserEndpoint = (token, type, id, body) => {
  var config = {
    method: 'post',
    url: `${API_URL}/admin/${type}/create-admin-user/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: body,
  };
  return axios(config);
};

export const fetchPodcastAndEpisodeData = (token, query) => {
  var config = {
    method: 'get',
    url: `${API_URL}/agency/podcast-info?feedUrl=${query}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const fetchEpisodeAnalytics = (token, url, guid) => {
  var config = {
    method: 'get',
    url: `${API_URL}/agency/episode-analytics?feedUrl=${url}&episodeGuid=${guid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const fetchBoostQuantity = (token, yguuid, timing) => {
  var config = {
    method: 'get',
    url: `${API_URL}/agency/boost-info?yguuid=${yguuid}&timing=${timing}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const uploadPodcasterImage = (token, body) => {
  var config = {
    method: 'post',
    url: `${API_URL}/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    data: body,
  };
  return axios(config);
};
