import "./usersdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { usersColumns } from "../../datatablesource";
import { useState, useEffect } from "react";
import {getEndpoint, getUserTypeListEndpoint, addUserEndpoint, updateEndpoint} from '../../utils/services';
import {GET_USERS, UPDATE_DELETE_USERS} from '../../src-constants/Urls'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { updateError, generateRandom, isValidEmail, isValidField } from "../../src-constants/Functions";
import { CANCEL_TEXT, USER_EMAIL_TEXT, USER_FIRST_NAME_TEXT, USER_LAST_NAME_TEXT, USER_SELECT_TEXT, USER_TYPE_TEXT } from "../../src-constants/Constants";
import { useSelector } from "react-redux";

const UsersDatable = () => {
  const token = useSelector(state => state.user.token);
  const [fetchedUserInfo, setFetchedUserInfo] = useState([{ firstName:'', lastName:'', email: '', userType: '', refUserTypeId:''}]);
  const typesOfUser = [
    {value: "", text: "--Select--"}, 
    {value: "agency", text: "Agency"}, 
    {value: "brand", text: "Brand"}, 
  ]
  const initialState =  {firstName: '', lastName: '', email: '', userType: typesOfUser, refUserTypeId: ''}
  const [userInfo, setUserInfo] = useState([initialState]);
  const {firstName, lastName, email, userType, refUserTypeId} = userInfo;
  const [isFetched, setIsFetched] = useState(false);
  const [userTypeData, setUserTypeData] = useState([{_id: "", name: "", image: "", address1: "", address2: "", city: "", state: "", pincode: ""}]);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const [isUpdating, setIsUpdating] = useState(false)

  const fetchUserDataRequest = (token, USER_URL) => {
    getEndpoint(token, USER_URL)
    .then(res => {
      setFetchedUserInfo(res.data.response.data)
    })
    .catch(error => { return updateError(error.res.data.Message, setError); })
  }

  useEffect(() => {
    fetchUserDataRequest(token, GET_USERS);
  }, []);

  const options = typesOfUser.map((option) => {
  return <option value={option.value}>{option.text}</option>
  })

  const handleOnChangeText = (value, fieldName) => {
    setUserInfo({...userInfo, [fieldName]: value});
    if(fieldName === "userType" && value !== ""){
      getUserTypeListEndpoint(token, value)
      .then((res) => {
        setUserTypeData(res.data.response);
        setIsFetched(true);
      })
      .catch(error => { return updateError(error.res.data.Message, setError); })
    }
  };

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    setIsFetched(false);
    setUserInfo(initialState);
  };

  const isValidForm = () => {
    // if (isValidField(firstName) || isValidField(lastName))
    //   return updateError('Fields Missing!', setError);
    if (isValidField(userType))
      return updateError('Fields Missing!', setError);
    if (refUserTypeId === undefined || refUserTypeId === "")
      return updateError('Fields Missing!', setError);
    if (!isValidEmail(email))
      return updateError('Invalid Email!', setError);
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValidForm()) {
      const addUserPayload = { firstName, lastName, email }
      addUserEndpoint(token, userType, refUserTypeId, addUserPayload)
      .then((response) => {
        setIsOpen(false);
        setIsFetched(false);
        fetchUserDataRequest(token, GET_USERS);
        setUserInfo(initialState)
      })
      .catch(error => { return updateError(error.response.data.Massage, setError); })
    }
  }

  const handleOpenUpdate = (params) => {
    setIsUpdating(true);
    setIsOpen(true);
    // handleOnChangeText('agency', 'userType') // change the agency string to params
    setUserInfo({
      firstName: params.row.firstName, 
      lastName: params.row.lastName, 
      refUserTypeId: params.row._id,
    })
  };
  const handleCloseUpdate = () => {
    setIsUpdating(false);
    setIsOpen(false);
    setIsFetched(false);
    setUserInfo(initialState)
  };
  
  const handleDeleteUser = (params) => {
    const deleteUserPayload = {
      firstName: params.row.firstName,
      lastName: params.row.lastName,
      isActive: false,
    }
    updateEndpoint(token, UPDATE_DELETE_USERS, params.row._id, deleteUserPayload)
    .then((res) => {
      fetchUserDataRequest(token, GET_USERS);
    })
    .catch(error => { return updateError(error.res.data.Message, setError); })
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
              <div 
              onClick={() => {handleOpenUpdate(params)}} 
              className="viewButton">Update</div>
            <div
              className="deleteButton"
              onClick={() => {
                handleDeleteUser(params)
              }}
            >
              Deactivate
            </div>
          </div>
        );
      },
    },
  ];

  const isValidFormUpdate = () => {
    // if (isValidField(firstName) || isValidField(lastName))
    //   return updateError('First or Last Name Missing!', setError);
    return true;
  };

  const handleUpdateUser = (event) => {
    event.preventDefault();
    if (isValidFormUpdate()) {
      const updateUserPayload = { firstName, lastName, isActive: true }
      updateEndpoint(token, UPDATE_DELETE_USERS, refUserTypeId, updateUserPayload)
      .then((res) => {
        setIsUpdating(false);
        setIsOpen(false);
        setIsFetched(false);
        setUserInfo(initialState);
        fetchUserDataRequest(token, GET_USERS);
      })
      .catch(error => { return updateError(error.res.data.Message, setError); })
    }
  }

  return (
    <>
    <div className="datatable">
      <div className="datatableTitle">
        Users
        <Button onClick={handleOpen} className="link">Add User</Button>
      </div>
      <DataGrid
        className="datagrid"
        rows={fetchedUserInfo}
        columns={usersColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) =>  generateRandom()}
      />
    </div>
    
    <Modal
    open={isOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box className="boxStyle">
    {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
      <div className="addTitle">{isUpdating ? 'UPDATE' : 'ADD'} USER</div>
      <form onSubmit={isUpdating ? handleUpdateUser : handleSubmit}>  

      {!isUpdating ? 
        <>
        <div className="formInput">
          <label>{USER_TYPE_TEXT}</label>
          <select value={userType} onChange={(e) => handleOnChangeText(e.target.value, 'userType')}>
		        {options}
		      </select>
        </div>

        {isFetched ? 
        <div>
        <div className="formInput">
          <label>{USER_SELECT_TEXT} {userType}</label>
          <select eventType={refUserTypeId} onChange={(event) => {handleOnChangeText(event.target.value, 'refUserTypeId')}}>
            <option value="">--Select--</option>
            { userTypeData.length
              ? userTypeData.map((data) => (
                <option value={data._id}>{data.name}</option>
              ))
              : null
            }
          </select>
        </div>

        <div className="formInput">
          <label>{USER_FIRST_NAME_TEXT}</label>
          <input type="text" placeholder="John" name="firstName" value={firstName || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'firstName')}}/>
        </div>

        <div className="formInput">
          <label>{USER_LAST_NAME_TEXT}</label>
          <input type="text" placeholder="Doe" name="lastName" value={lastName || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'lastName')}}/>
        </div>

        <div className="formInput">
          <label>{USER_EMAIL_TEXT}</label>
          <input type="email" placeholder="johndoe@gmail.com" name="email" value={email || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'email')}}/>
        </div>
        </div>
        : null}
        </>

        :

        <>
        <div className="formInput">
          <label>{USER_FIRST_NAME_TEXT}</label>
          <input type="text" placeholder="John" name="firstName" value={firstName || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'firstName')}}/>
        </div>

        <div className="formInput">
          <label>{USER_LAST_NAME_TEXT}</label>
          <input type="text" placeholder="Doe" name="lastName" value={lastName || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'lastName')}}/>
        </div>
        </>

      }

        <div className="buttonsContainer">
        <button>{isUpdating? 'UPDATE' : 'ADD' }</button>
        <div onClick={isUpdating? handleCloseUpdate : handleClose} className="closeText">{CANCEL_TEXT}</div>
        </div>

      </form>
    </Box>
    </Modal>
    </>
  );
};

export default UsersDatable;
