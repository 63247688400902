import "./invitation.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import InvitationDatable from "../../components/invitation-datatable/InvitationDatable"


const Invitation = () => {
  return (
    <div className="list">
      <Sidebar/> 
      <div className="listContainer">
        <Navbar/>
        <InvitationDatable/>
      </div>
    </div>
  )
}

export default Invitation
