import "./episodetranscription.css";
import { useState, useEffect } from "react";
import {fetchEpisodeAnalytics} from '../../utils/services';
import { useSelector } from "react-redux";
import { CKEditor } from 'ckeditor4-react';
import { height } from "@mui/system";

const heightVar = "2000";
const toolbarConfig = {
  height: heightVar,
  toolbar: 'Full',
  allowedContent: true,
  startupFocus: true,
};

const EpisodeTranscription = ({feedUrl, episodeGuid}) => {
  const [episodeTranscriptionData, setEpisodeTranscriptionData] = useState("");
  const user = useSelector(state => state.user);

  useEffect(() => {
    fetchEpisodeAnalytics(user.token, feedUrl, episodeGuid)
    .then(response => {
      setEpisodeTranscriptionData(response.data.Response.returnData.transcription);
    })
    .catch(error => {
      console.log(error);
    });
  }, []);

  return (
    <>
    { episodeTranscriptionData.length ?
      <div className="transcription">
        <CKEditor initData={episodeTranscriptionData} config={toolbarConfig} />
        {/* {episodeTranscriptionData} */}
      </div>
      : <div className="noKeywordList">No transcription available for this episode</div>
    }
    </>
  );
};

export default EpisodeTranscription;
