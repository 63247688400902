import "./brandspodcastlist.css";
import { Link } from "react-router-dom";
import React, { useState, useMemo, useEffect } from 'react';
import Pagination from '../pagination/Pagination';
import { getEndpoint } from '../../utils/services';
import { GET_BRAND_MENTIONED_LIST } from '../../src-constants/Urls'
import { useSelector } from "react-redux";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

let PageSize = 5;

const BrandedPodcastList = () => {
  const [brandedList, setBrandedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.user);

  useEffect(() => {
    setIsLoading(true);
    getEndpoint(user.token, GET_BRAND_MENTIONED_LIST)
    .then(response => {
      setBrandedList(response.data.response)
      setIsLoading(false);
    })
    .catch(error => {
      console.log(error);
      setIsLoading(false);
    });
  }, []);

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = brandedList.slice(firstPageIndex, lastPageIndex);
  
  return (
  <>
  {!isLoading ?
    <div>
    {currentTableData.length ?
    currentTableData.map((row, index) => (
      <div key={index}>
        <div className="boostedListItem">
        
        <img src={"https://media.rss.com/vsheekhan/20220511_070517_6080d48072dbfd29608a87ffb8fa1e05.jpg"} alt="" className="imageMain" />
        
        <div className="boostedListItemInfo">
            <div className="podcastName">
            {row.podcastTitle}
            </div>
            <div className="podcastAuthor">
            {row.podcastAuthor}
            </div>
        </div>

        <div className="boostedListItemInfo">
          <div className="episodeNumber">
          {row.episodeTitle}
          </div>
          <div className="episodeName" numberOfLines={1}>
          Recently Mentioned Podcast
          </div>
        </div>

        <div className="boostedListItemInfo">
          <div className="episodeNumber">
          Count: {row.count}
          </div>
          <div className="episodeName" numberOfLines={1}>
          Mentioned Frequency
          </div>
        </div>
      
      </div>
      </div>
    ))
    :<div className="noBoostedList">No recently mentioned podcast</div>
  }
    </div>
    :
    <div className="spinner"></div>
  }
    <Pagination
      className="pagination-bar"
      currentPage={currentPage}
      totalCount={brandedList.length}
      pageSize={PageSize}
      onPageChange={page => setCurrentPage(page)}
    />
  </>
  );
};

export default BrandedPodcastList;
