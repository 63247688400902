export const eventColumns = [
  { field: "_id", headerName: "ID", width: 70 },
  {field: "title",headerName: "Title",width: 350,},
  {field: "description", headerName: "Description", width: 150,},
  {field: "image", headerName: "Image", width: 150,},
  { field: "url", headerName: "URL", width: 150 },
  { field: "author", headerName: "Author", width: 150 },
  { field: "type", headerName: "Type", width: 150 },
  { field: "eventStartDate", headerName: "Event Start Date", width: 150 },
  { field: "eventStartTime", headerName: "Event Start Time", width: 150 },
  { field: "eventEndDate", headerName: "Event End Date", width: 150 },
  { field: "eventEndTime", headerName: "Event End Time", width: 150 },
];

export const spotlightColumns = [
  { field: "_id", headerName: "ID", width: 70 },
  { field: "title",headerName: "Title",width: 300 },
  { field: "description", headerName: "Description", width: 150 },
  {
    field: "imagedisplay",
    headerName: "Display",
    width: 65,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.image} alt="avatar" />
        </div>
      );
    },
  },
  { field: "image", headerName: "Image", width: 150 },
  { field: "url", headerName: "URL", width: 150 },
  { field: "author", headerName: "Author", width: 150 },
  { field: "ownerName", headerName: "Owner Name", width: 150 },
  // { field: "podcastId", headerName: "PodcastId", width: 150 },
];

export const featuredColumns = [
  { field: "_id", headerName: "ID", width: 70 },
  { field: "title",headerName: "Title",width: 300 },
  { field: "description", headerName: "Description", width: 150 },
  {
    field: "imagedisplay",
    headerName: "Display",
    width: 65,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.image} alt="avatar" />
        </div>
      );
    },
  },
  { field: "image", headerName: "Image", width: 150 },
  { field: "url", headerName: "URL", width: 150 },
  { field: "author", headerName: "Author", width: 150 },
  { field: "ownerName", headerName: "Owner Name", width: 150 },
  // { field: "podcastId", headerName: "PodcastId", width: 150 },
];

export const recommendedColumns = [
  { field: "podcastId", headerName: "ID", width: 70 },
  { field: "title",headerName: "Title",width: 300 },
  { field: "description", headerName: "Description", width: 150 },
  {
    field: "imagedisplay",
    headerName: "Display",
    width: 65,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.image} alt="avatar" />
        </div>
      );
    },
  },
  { field: "image", headerName: "Image", width: 150 },
  { field: "url", headerName: "URL", width: 150 },
  { field: "author", headerName: "Author", width: 150 },
];

export const agencyColumns = [
  { field: "_id", headerName: "ID", width: 100 },
  { field: "name", headerName: "Name", width: 200 },
  { field: "image",headerName: "Image",width: 200 },
  { field: "address1", headerName: "Address Line 1", width: 200 },
  { field: "address2", headerName: "Address Line 2", width: 200 },
  { field: "city", headerName: "City", width: 200 },
  { field: "state",headerName: "State",width: 200 },
  { field: "pincode", headerName: "Pincode", width: 200 },
];

export const brandsColumns = [
  { field: "_id", headerName: "ID", width: 100 },
  { field: "name", headerName: "Name", width: 200 },
  { field: "address1", headerName: "Address Line 1", width: 200 },
  { field: "address2", headerName: "Address Line 2", width: 200 },
  { field: "city", headerName: "City", width: 200 },
  { field: "state",headerName: "State",width: 200 },
  { field: "pincode", headerName: "Pincode", width: 200 },
  {
    field: "isActive",
    headerName: "Status",
    width: 100,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.isActive}`}>
          { (params.row.isActive === true) ?
          <>Active</> :
          <>Inactive</> }
        </div>
      );
    },
  },
];

export const podcastersInAgencyColumns = [
  { field: "_id", headerName: "ID", width: 70 },
  { field: "title",headerName: "Title",width: 300 },
  { field: "description", headerName: "Description", width: 150 },
  { field: "authorImageKey", headerName: "Author Image Key", width: 300 },
  { field: "url", headerName: "URL", width: 150 },
  { field: "author", headerName: "Author", width: 200 },
  { field: "ownerName", headerName: "Owner Name", width: 200 },
  { field: "image", headerName: "Podcast Image", width: 150 },
  {
    field: "isActive",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          { (params.row.status === "Active") ?
          <>Active</> :
          <>Inactive</> }
        </div>
      );
    },
  },
];

export const usersColumns = [
  { field: "_id", headerName: "ID", width: 100 },
  { field: "firstName", headerName: "First Name", width: 200 },
  { field: "lastName",headerName: "Last Name",width: 200 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "userType", headerName: "User Type", width: 250 },
  {
    field: "isActive",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.isActive}`}>
          { (params.row.isActive === true) ?
          <>Active</> :
          <>Inactive</> }
        </div>
      );
    },
  },
];

export const claimPodcastAgencyColumns = [
  { field: "_id", headerName: "ID", width: 100 },
  { field: "feedUrl", headerName: "Feed URL", width: 200 },
  { field: "title",headerName: "Title",width: 200 },
  { field: "description", headerName: "Description", width: 200 },
  { field: "podcastImage", headerName: "Image", width: 200 },
  { field: "ownerName", headerName: "Owner Name", width: 200 },
  { field: "ownerEmail",headerName: "Owner Email",width: 200 },
  { field: "userId", headerName: "User ID", width: 200 },  
  { field: "split", headerName: "Split", width: 200 },
];

export const inviteColumns = [
  { field: "_id", headerName: "ID", width: 100 },
  { field: "firstName", headerName: "First Name", width: 150 },
  { field: "lastName",headerName: "Last Name",width: 150 },
  { field: "email", headerName: "Email", width: 220 },
  { field: "feedUrl", headerName: "Feed URL", width: 200 },
  { field: "split", headerName: "Split(%)", width: 100 },
  { field: "agencyId", headerName: "Agency ID", width: 100 },
  {
    field: "isActive",
    headerName: "Status",
    width: 100,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.isActive}`}>
          { (params.row.isActive === true) ?
          <>Active</> :
          <>Inactive</> }
        </div>
      );
    },
  },
];

export const userColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "age",
    headerName: "Age",
    width: 100,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },
  },
];

//temporary data
export const userRows = [
  {
    id: 1,
    username: "Snow",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: "active",
    email: "1snow@gmail.com",
    age: 35,
  },
  {
    id: 2,
    username: "Jamie Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "2snow@gmail.com",
    status: "passive",
    age: 42,
  },
  {
    id: 3,
    username: "Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "3snow@gmail.com",
    status: "pending",
    age: 45,
  },
  {
    id: 4,
    username: "Stark",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "4snow@gmail.com",
    status: "active",
    age: 16,
  },
  {
    id: 5,
    username: "Targaryen",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "5snow@gmail.com",
    status: "passive",
    age: 22,
  },
  {
    id: 6,
    username: "Melisandre",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "6snow@gmail.com",
    status: "active",
    age: 15,
  },
  {
    id: 7,
    username: "Clifford",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "7snow@gmail.com",
    status: "passive",
    age: 44,
  },
  {
    id: 8,
    username: "Frances",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "8snow@gmail.com",
    status: "active",
    age: 36,
  },
  {
    id: 9,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "pending",
    age: 65,
  },
  {
    id: 10,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    age: 65,
  },
];
