import "./login.scss";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { updateWithoutIdEndpoint } from '../../utils/services';
import { isValidEmail, updateError } from "../../src-constants/Functions";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../reducers/profileReducer";
import { ADMIN_LOGIN, RESET_PASSWORD } from '../../src-constants/Urls';

const Reset = () => {
  const [inputs, setInputs] = useState({
    newPassword: '',
    confirmPassword: '',
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs(values => ({ ...values, [name]: value }));
  };

  useEffect(()=>{
    const initial = {
        newPassword: '',
        confirmPassword: '',
        email: user.email,
      }
      setInputs(initial)
  }, [])

  const isValidForm = () => {
    if (!inputs.newPassword.trim() || inputs.newPassword.length < 8) {
        updateError('New Password is less than 8 characters!', setError);
        return false;
      }
      if (inputs.newPassword !== inputs.confirmPassword) {
        updateError("Password doesn't match!", setError);
        return false;
      }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isValidForm()) {
      setIsLoading(true);
      updateWithoutIdEndpoint(user.token, RESET_PASSWORD, { userId: user.userId, oldPassword: inputs.password, newPassword: inputs.newPassword })
          .then(response => {
            if(response.data.Message !== 'Password reset successfully'){
                updateError(error.response.data.Message, setError);
            }
            dispatch(updateUser({ ...user, isUpdatePasswordRequired: false }));
            navigate('/')
          })
          .catch(error => {
            updateError(error.response.data.Message, setError);
          })
          .finally(() => setIsLoading(false));
    }
  };

  return (
    <div className="loginContainer">
      <div className="loginHeading">YourGold Admin Panel</div>
      <div className="loginSubHeading">Please reset your password</div>
      <form onSubmit={handleSubmit} className="loginForm">
        <input
          type="email"
          name="email"
          value={inputs.email}
          disabled={true}
          className="inputField"
          placeholder="Email"
        />
        <input
              type="password"
              name="password"
              value={inputs.password}
              onChange={handleChange}
              className="inputField"
              placeholder="Current Password"
            />
            <input
              type="password"
              name="newPassword"
              value={inputs.newPassword}
              onChange={handleChange}
              className="inputField"
              placeholder="New Password"
            />
            <input
              type="password"
              name="confirmPassword"
              value={inputs.confirmPassword}
              onChange={handleChange}
              className="inputField"
              placeholder="Confirm Password"
            />
        {error && <div className="errorMsg">{error}</div>}
        {isLoading ? (
          <div className="loginButton">
            <div className="spinner"></div>
          </div>
        ) : (
          <input className="loginButton" type="submit" />
        )}
      </form>
    </div>
  );
};

export default Reset;
