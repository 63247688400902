import "./branddatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { brandsColumns } from "../../datatablesource";
import { useState, useEffect } from "react";
import {getEndpoint, saveEndpoint, updateEndpoint} from '../../utils/services';
import { GET_ALL_BRANDS } from '../../src-constants/Urls'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { updateError, generateRandom, isValidURL, isValidField, isValidPincode } from "../../src-constants/Functions";
import { CANCEL_TEXT } from "../../src-constants/Constants";
import { useSelector } from "react-redux";

const BrandDatatable = () => {
  const token = useSelector(state => state.user.token);
  const initialState = {_id:"", name: "", address1: "", address2: "", city: "", state: "", pincode: ""}
  const [fetchedBrandsData, setFetchedBrandsData] = useState([initialState]);
  const [brandsData, setBrandsData] = useState([initialState]);
  const {name, address1, address2, city, state, pincode} = brandsData;
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const [brandsID, setBrandsID] = useState('');
  const [isUpdating, setIsUpdating] = useState(false)

  const fetchBrandsDataRequest = (token, BRANDS_URL) => {
    getEndpoint(token, BRANDS_URL)
    .then(res => {
      setFetchedBrandsData(res.data.response)
    })
    .catch(error => { return updateError(error.res.data.Message, setError); })
  }

  //get all agencies
  useEffect(() => {
    fetchBrandsDataRequest(token, GET_ALL_BRANDS);
  }, []);

  const handleOnChangeText = (value, fieldName) => {
    setBrandsData({...brandsData, [fieldName]: value});
  };

  const handleClose = () => {
    setIsOpen(false);
    setBrandsData(initialState)
  };

  const isValidForm = () => {
    if ( isValidField(name) || isValidField(address1) || isValidField(address2) || isValidField(city) || isValidField(state)) 
      return updateError('Fields Missing', setError);
    if (!isValidPincode(pincode))
      return updateError('Pincode is incorrect!', setError);
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValidForm()) {
      const payload = { name, address1, address2, city, state, pincode }
      saveEndpoint(token, GET_ALL_BRANDS, payload)
      .then((response) => {
        setIsOpen(false);
        setBrandsData(initialState)
        fetchBrandsDataRequest(token, GET_ALL_BRANDS);
      })
      .catch(error => { return updateError(error.response.data.Message, setError); })
    }
  }

  const handleOpenUpdate = (params) => {
    setIsUpdating(true)
    setIsOpen(true)
    setBrandsID(params.row._id)
    setBrandsData({
      name: params.row.name, 
      address1: params.row.address1, 
      address2: params.row.address2, 
      city: params.row.city, 
      state: params.row.state, 
      pincode: params.row.pincode,
    }) 
  };

  const handleCloseUpdate = () => {
    setIsUpdating(false)
    setIsOpen(false);
    setBrandsData(initialState)
  };
  
  const handleDeleteBrand = (params) => {
    const payload = {
      name: params.row.name, 
      address1: params.row.address1, 
      address2: params.row.address2, 
      city: params.row.city, 
      state: params.row.state, 
      pincode: Number(params.row.pincode),
      isActive: false,
    }

    updateEndpoint(token, GET_ALL_BRANDS, params.row._id, payload)
    .then((response) => {
      // fetchBrandsDataRequest(token, GET_ALL_BRANDS);
      setFetchedBrandsData(fetchedBrandsData.filter((item) => item._id !== params.row._id));
    })
    .catch(error => { return updateError(error.response.data.Message, setError); })
  };

  const handleUpdateBrand = (event) => {
    event.preventDefault();
    if (isValidForm()) {
      const payload = { name, address1, address2, city, state, pincode, isActive: true }

      updateEndpoint(token, GET_ALL_BRANDS, brandsID, payload)
      .then((response) => {
        setIsUpdating(false);
        setIsOpen(false);
        setBrandsData(initialState)
        fetchBrandsDataRequest(token, GET_ALL_BRANDS);
      })
      .catch(error => { return updateError(error.response.data.Message, setError); })
    }
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
              <div 
              onClick={() => {handleOpenUpdate(params)}} 
              className="viewButton">Update</div>
            <div
              className="deleteButton"
              onClick={() => {
                handleDeleteBrand(params)
              }}
            >
              Deactivate
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
    <div className="datatable">
      {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
      <div className="datatableTitle">
        Brands
        <Button onClick={() => setIsOpen(true)} className="link">Add Brand</Button>
      </div>
      <DataGrid
        className="datagrid"
        rows={fetchedBrandsData}
        columns={brandsColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) =>  generateRandom()}
      />
    </div>
    
    <Modal
    open={isOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box className="boxStyle">
    {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
      <div className="addTitle">{isUpdating ? 'Update' : 'Add'} Brand</div>
      <form onSubmit={isUpdating ? handleUpdateBrand : handleSubmit}>  

        <div className="formInput">
          <label>Name</label>
          <input type="text" placeholder="John" name="name" value={name || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'name')}}/>
        </div>

        <div className="formInput">
          <label>Address Line 1</label>
          <input type="text" placeholder="address line 1" name="address1" value={address1 || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'address1')}}/>
        </div>

        <div className="formInput">
          <label>Address Line 2</label>
          <input type="text" placeholder="address line 2" name="address2" value={address2 || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'address2')}}/>
        </div>

        <div className="formInput">
          <label>City</label>
          <input type="text" placeholder="city" name="city" value={city || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'city')}}/>
        </div>

        <div className="formInput">
          <label>State</label>
          <input type="text" placeholder="state" name="state" value={state || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'state')}}/>
        </div>

        <div className="formInput">
          <label>Pincode</label>
          <input type="text" placeholder="pincode" name="pincode" value={pincode || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'pincode')}}/>
        </div>

        <div className="buttonsContainer">
        <button>{isUpdating? 'UPDATE' : 'ADD' }</button>
        <div onClick={isUpdating? handleCloseUpdate : handleClose} className="closeText">{CANCEL_TEXT}</div>
        </div>

      </form>
    </Box>
    </Modal>
    </>
  );
};

export default BrandDatatable;
