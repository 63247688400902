import "./episodekeyword.css";
import { useState, useEffect } from "react";
import {fetchEpisodeAnalytics} from '../../utils/services';
import { useSelector } from "react-redux";

const EpisodeKeyword = ({feedUrl, episodeGuid}) => {
  const [episodeKeywordsData, setEpisodeKeywordsData] = useState([]);
  const user = useSelector(state => state.user);

  useEffect(() => {
    fetchEpisodeAnalytics(user.token, feedUrl, episodeGuid)
    .then(response => {
      setEpisodeKeywordsData(response.data.Response.returnData.keywords);
    })
    .catch(error => {console.log(error);});
  }, []);

  return (
    <div className="keywordListContainer">
      {/* { 
      episodeKeywordsData.map((item) => (
      <div className="keywordList">{item}</div>
      ))} */}
      { episodeKeywordsData.length ?
       episodeKeywordsData.map((item) => (
        <div className="keywordList">{item}</div>
        ))
      : <div className="noKeywordList">No Keywords for this episode</div>
      }
    </div>
  );
};

export default EpisodeKeyword;
