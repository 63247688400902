import "./event.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import EventsDatatable from "../../components/events-datatable/EventsDatatable"

const Event = () => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <EventsDatatable/>
      </div>
    </div>
  )
}

export default Event;
