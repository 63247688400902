import "./episodecategory.css";
import { useState, useEffect } from "react";
import {fetchEpisodeAnalytics} from '../../utils/services';
import { useSelector } from "react-redux";

const EpisodeCategory = ({feedUrl, episodeGuid}) => {
  const [episodeCategoryData, setEpisodeCategoryData] = useState([]);
  const user = useSelector(state => state.user);

  useEffect(() => {
    fetchEpisodeAnalytics(user.token, feedUrl, episodeGuid)
    .then(response => {
      setEpisodeCategoryData(response.data.Response.returnData.episodeCategories);
    })
    .catch(error => {console.log(error);});
  }, []);

  return (
    <div className="categoryListsContainer">
      { episodeCategoryData.length ?
      episodeCategoryData.map((item) => (
      <div className="categoryLists">{item}</div>
      ))
      : <div className="noKeywordList">No category for this episode</div>
    }
    </div>
  );
};

export default EpisodeCategory;
