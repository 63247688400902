export const CREATE_EVENT = '/admin/event';
export const GET_EVENT = '/event';
export const GET_SPOTLIGHT = '/spotlight';
export const GET_FEATURED = '/featured-podcast';
export const GET_RECOMMENDED = '/recommended-podcast';
export const UPDATE_EVENT = '/admin/event';
export const CREATE_ADMIN_SPOTLIGHT = '/admin/spotlight';
export const CREATE_ADMIN_FEATURED = '/admin/featured-podcast';
export const CREATE_ADMIN_RECOMMENDED = '/admin/recommended-podcast';
export const UPDATE_ADMIN_SPOTLIGHT = '/admin/spotlight';
export const UPDATE_ADMIN_FEATURED = '/admin/featured-podcast';
export const UPDATE_ADMIN_RECOMMENDED = '/admin/recommended-podcast';
export const ADMIN_AGENCY = '/admin/agency';
export const PODCASTERS_IN_AGENCY = '/agency/podcasters';
export const REGISTER_PODCASTERS_IN_AGENCY = '/agency/register-podcaster';
export const GET_USERS = '/admin/users';
export const UPDATE_DELETE_USERS = '/admin/update-user';
export const RESET_PASSWORD = '/admin/reset-password';
export const ADMIN_LOGIN = '/admin/login';
export const FETCH_PODCAST = '/agency/fetch-rss-feed';
export const FETCH_INVITATION_DATA = '/agency/get-all-podcaster-invite';
export const SEND_INVITE = '/agency/send-podcaster-invite';
export const UPDATE_INVITE = '/agency/update-podcaster-invite';
export const DELETE_INVITE = '/agency/deactivate-podcaster-invite';
export const GET_WALLET_BALANCE = '/agency/wallet-balance';
export const GET_BOOSTED_LIST = '/agency/boosts';
export const FEATURED_PODCASTERS = '/admin/featured-podcaster';
export const GET_ALL_BRANDS = '/admin/brand';
export const GET_BRAND_MENTIONED_LIST = '/brand/brand-mentions';
export const UPDATE_BRAND_MENTIONED_LIST =
  '/agency/update-podcast-brand-appearance';
export const DELETE_BRAND_MENTIONED_LIST =
  '/agency/delete-podcast-brand-appearance';
export const GET_TRANSACTIONS = '/admin/transactions';
