import "./login.scss";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { postEndpointWithoutToken, putEndpointWithoutToken } from '../../utils/services';
import { isValidEmail, updateError } from "../../src-constants/Functions";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../reducers/profileReducer";
import { ADMIN_LOGIN } from '../../src-constants/Urls';

const Login = () => {
  const [inputs, setInputs] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs(values => ({ ...values, [name]: value }));
  };

  const isValidForm = () => {
    if (!isValidEmail(inputs.email)) {
      updateError('Invalid Email!', setError);
      return false;
    }
    if (!inputs.password.trim() || inputs.password.length < 8) {
      updateError('Password is less than 8 characters!', setError);
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isValidForm()) {
      setIsLoading(true);
      postEndpointWithoutToken(ADMIN_LOGIN, { email: inputs.email, password: inputs.password })
        .then(response => {
          dispatch(addUser({ ...response.data.Response }));
          if (response.data.Response.isUpdatePasswordRequired !== undefined && response.data.Response.isUpdatePasswordRequired) {
            navigate('/reset')
          }
        })
        .catch(error => {
          updateError(error.response.data.Message, setError);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <div className="loginContainer">
      <div className="loginHeading">YourGold Admin Panel</div>
      <div className="loginSubHeading">Please sign-in to your account and start the adventure</div>
      <form onSubmit={handleSubmit} className="loginForm">
        <input
          type="email"
          name="email"
          value={inputs.email}
          onChange={handleChange}
          className="inputField"
          placeholder="Email"
        />
        <input
            type="password"
            name="password"
            value={inputs.password}
            onChange={handleChange}
            className="inputField"
            placeholder="Password"
          />
        {error && <div className="errorMsg">{error}</div>}
        {isLoading ? (
          <div className="loginButton">
            <div className="spinner"></div>
          </div>
        ) : (
          <input className="loginButton" type="submit" />
        )}
      </form>
    </div>
  );
};

export default Login;
