import "./agencydatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { agencyColumns } from "../../datatablesource";
import { useState, useEffect } from "react";
import {getEndpoint, saveEndpoint, updateEndpoint} from '../../utils/services';
import { ADMIN_AGENCY } from '../../src-constants/Urls'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { updateError, generateRandom, isValidURL, isValidField, isValidPincode } from "../../src-constants/Functions";
import { CANCEL_TEXT } from "../../src-constants/Constants";
import { useSelector } from "react-redux";

const AgencyDatable = () => {
  const token = useSelector(state => state.user.token);
  const initialState = {_id:"", name: "", image: "", address1: "", address2: "", city: "", state: "", pincode: "", mobile:""}
  const [fetchedAgencyData, setFetchedAgencyData] = useState([initialState]);
  const [agencyData, setAgencyData] = useState([initialState]);
  const {name, image, address1, address2, city, state, pincode, mobile} = agencyData;
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const [agencyID, setAgencyID] = useState('');
  const [isUpdating, setIsUpdating] = useState(false)

  const fetchAgencyDataRequest = (token, ADMIN_AGENCY_URL) => {
    getEndpoint(token, ADMIN_AGENCY_URL)
    .then(res => {
      setFetchedAgencyData(res.data.response)
    })
    .catch(error => { return updateError(error.res.data.Message, setError); })
  }

  //get all agencies
  useEffect(() => {
    fetchAgencyDataRequest(token, ADMIN_AGENCY);
  }, []);

  const handleOnChangeText = (value, fieldName) => {
    setAgencyData({...agencyData, [fieldName]: value});
  };

  const handleClose = () => {
    setIsOpen(false);
    setAgencyData(initialState)
  };

  const isValidForm = () => {
    if ( isValidField(name) || isValidField(address1) || isValidField(address2) || isValidField(city) || isValidField(state)) 
      return updateError('Fields Missing', setError);
    if (!isValidURL(image))
      return updateError('Image URL is incorrect!', setError);
    if (!isValidPincode(pincode))
      return updateError('Pincode is incorrect!', setError);  
    if(!isUpdating){
      if (isNaN(mobile) || mobile.length<10)
      return updateError('Please enter correct Mobile Number', setError);   
    } 
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValidForm()) {
      const addAgencyPayload = { name, image, address1, address2, city, state, pincode, mobile }
      saveEndpoint(token, ADMIN_AGENCY, addAgencyPayload)
      .then((response) => {
        setIsOpen(false);
        setAgencyData(initialState)
        fetchAgencyDataRequest(token, ADMIN_AGENCY);
      })
      .catch(error => { return updateError(error.response.data.Message, setError); })
    }
  }

  const handleOpenUpdate = (params) => {
    setIsUpdating(true)
    setIsOpen(true)
    setAgencyID(params.row._id)
    setAgencyData({
      name: params.row.name, 
      image: params.row.image, 
      address1: params.row.address1, 
      address2: params.row.address2, 
      city: params.row.city, 
      state: params.row.state, 
      pincode: params.row.pincode,
    }) 
  };

  const handleCloseUpdate = () => {
    setIsUpdating(false)
    setIsOpen(false);
    setAgencyData(initialState)
  };
  
  const handleDeleteAgency = (params) => {
    const payload = {
      name: params.row.name, 
      image: params.row.image, 
      address1: params.row.address1, 
      address2: params.row.address2, 
      city: params.row.city, 
      state: params.row.state, 
      pincode: Number(params.row.pincode),
      isActive: false,
    }

    updateEndpoint(token, ADMIN_AGENCY, params.row._id, payload)
    .then((response) => {
      setFetchedAgencyData(fetchedAgencyData.filter((item) => item._id !== params.row._id))
    })
    .catch(error => { return updateError(error.response.data.Message, setError); })
  };

  const handleUpdateAgency = (event) => {
    event.preventDefault();
    if (isValidForm()) {
      const payload = { name, image, address1, address2, city, state, pincode, isActive: true }

      updateEndpoint(token, ADMIN_AGENCY, agencyID, payload)
      .then((response) => {
        setIsUpdating(false);
        setIsOpen(false);
        setAgencyData(initialState)
        fetchAgencyDataRequest(token, ADMIN_AGENCY);
      })
      .catch(error => { return updateError(error.response.data.Message, setError); })
    }
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
              <div 
              onClick={() => {handleOpenUpdate(params)}} 
              className="viewButton">Update</div>
            <div
              className="deleteButton"
              onClick={() => {
                handleDeleteAgency(params)
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
    <div className="datatable">
      {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
      <div className="datatableTitle">
        Agency
        <Button onClick={() => setIsOpen(true)} className="link">Add Agency</Button>
      </div>
      <DataGrid
        className="datagrid"
        rows={fetchedAgencyData}
        columns={agencyColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) =>  generateRandom()}
      />
    </div>
    
    <Modal
    open={isOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box className="boxStyle">
    {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
      <div className="addTitle">{isUpdating ? 'Update' : 'Add'} Agency</div>
      <form onSubmit={isUpdating ? handleUpdateAgency : handleSubmit}>  

        <div className="formInput">
          <label>Name</label>
          <input type="text" placeholder="John" name="name" value={name || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'name')}}/>
        </div>

        <div className="formInput">
          <label>Image</label>
          <input type="text" placeholder="image url" name="image" value={image || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'image')}}/>
        </div>

        <div className="formInput">
          <label>Address Line 1</label>
          <input type="text" placeholder="address line 1" name="address1" value={address1 || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'address1')}}/>
        </div>

        <div className="formInput">
          <label>Address Line 2</label>
          <input type="text" placeholder="address line 2" name="address2" value={address2 || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'address2')}}/>
        </div>

        <div className="formInput">
          <label>City</label>
          <input type="text" placeholder="city" name="city" value={city || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'city')}}/>
        </div>

        <div className="formInput">
          <label>State</label>
          <input type="text" placeholder="state" name="state" value={state || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'state')}}/>
        </div>

        <div className="formInput">
          <label>Pincode</label>
          <input type="text" placeholder="pincode" name="pincode" value={pincode || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'pincode')}}/>
        </div>

        {!isUpdating? 
        <div className="formInput">
          <label>Mobile Number</label>
          <input type="text" maxLength={10} placeholder="mobile number" name="mobile" value={mobile || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'mobile')}}/>
        </div>
        :null}

        <div className="buttonsContainer">
        <button>{isUpdating? 'UPDATE' : 'ADD' }</button>
        <div onClick={isUpdating? handleCloseUpdate : handleClose} className="closeText">{CANCEL_TEXT}</div>
        </div>

      </form>
    </Box>
    </Modal>
    </>
  );
};

export default AgencyDatable;
