import "./episodebrandmentions.css";
import { useState, useEffect } from "react";
import {fetchEpisodeAnalytics, updateEndpoint} from '../../utils/services';
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { UPDATE_BRAND_MENTIONED_LIST, DELETE_BRAND_MENTIONED_LIST } from '../../src-constants/Urls'

const EpisodeBrandMentions = ({feedUrl, episodeGuid}) => {
  const [episodeBrandMentionsData, setEpisodeBrandMentionsData] = useState([]);
  const user = useSelector(state => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [brandMentionId, setBrandMentionId] = useState("");
  const [brandMentionCount, setBrandMentionCount] = useState("");
  const [brandAnalyticalId, setBrandAnalyticalId] = useState('');
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  useEffect(() => {
    fetchEpisodeAnalytics(user.token, feedUrl, episodeGuid)
    .then(response => {
      setEpisodeBrandMentionsData(response.data.Response.returnData.brands);
      setBrandAnalyticalId(response.data.Response.returnData._id);
    })
    .catch(error => {console.log(error);});
  }, []);

  const handleOpen = (params) => {
    setIsOpen(true)
    setBrandName(params.brand);
    setBrandMentionId(params.id);
    setBrandMentionCount(params.count);
  };
  const handleClose = () => {
    setIsOpen(false);
    setBrandName("");
    setBrandMentionId("");
    setBrandMentionCount("");
  };

  const handleSubmit = (event) => {
    setIsLoadingUpdate(true);
    event.preventDefault();
    const payload = {
      brandName: brandName, 
      count: Number(brandMentionCount),
    }
    updateEndpoint(user.token, UPDATE_BRAND_MENTIONED_LIST, brandMentionId, payload)
    .then((response) => {
      fetchEpisodeAnalytics(user.token, feedUrl, episodeGuid)
      .then(response => {
        setEpisodeBrandMentionsData(response.data.Response.returnData.brands);
        setIsOpen(false);
        setIsLoadingUpdate(false);
      })
      .catch(error => {console.log(error);setIsLoadingUpdate(false);});
    })
    .catch(error => {console.log(error); setIsLoadingUpdate(false);})
  }

  const handleDelete = (event) => {
    setIsLoadingDelete(true);
    event.preventDefault();
    const payload = {
    }
    updateEndpoint(user.token, DELETE_BRAND_MENTIONED_LIST, `${brandAnalyticalId}/${brandMentionId}`, payload)
    .then((response) => {
      fetchEpisodeAnalytics(user.token, feedUrl, episodeGuid)
      .then(response => {
        setEpisodeBrandMentionsData(response.data.Response.returnData.brands);
        setIsOpen(false);
        setIsLoadingDelete(false);
      })
      .catch(error => {console.log(error);setIsLoadingDelete(false);});
    })
    .catch(error => {console.log(error);setIsLoadingDelete(false);})
  }

  return (
    <>
    <div className="keywordListContainer">
      { episodeBrandMentionsData.length ?
       episodeBrandMentionsData.map((item) => (
        <div onClick={() => handleOpen(item)}>
        <div className="brandMentionsList">
          {item.brand}
          <div className="counts">{item.count}</div>
        </div>
        </div>
        ))
      : <div className="noKeywordList">No brand mentions for this episode.</div>
      }
    </div>

    <Modal
    open={isOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box className="boxStyle">
    {/* {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>} */}
      <div className="addTitle">Update Brand Mentions</div>
     
      <form onSubmit={handleSubmit}>

      <div className="formInput">
        <label>Brand Name</label>
        <input type="text" placeholder="brand" name="brandName" value={brandName || ""} onChange={(event) => {setBrandName(event.target.value)}}/>
      </div>

      <div className="buttonsContainer">
        <button>
          {!isLoadingUpdate ?
          "Update"
          :
          <div className="spinner"></div>
          }
        </button>
        <div onClick={handleDelete} className="deleteText">
          {!isLoadingDelete ?
          "Delete"
          :
          <div className="spinner"></div>
          }
        </div>
        <div onClick={handleClose} className="">Cancel</div>
      </div>

      </form>

    </Box>
    </Modal>
    </>
  );
};

export default EpisodeBrandMentions;
