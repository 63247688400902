import "./walletgoldbalance.scss";
import { GET_WALLET_BALANCE } from '../../src-constants/Urls'
import { useSelector } from "react-redux";
import {getEndpoint} from '../../utils/services';
import { useState, useEffect } from "react";

const WalletGoldBalance = () => {
  const user = useSelector(state => state.user);
  const [walletBalance, setWalletBalance] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getEndpoint(user.token, GET_WALLET_BALANCE)
    .then(response => {
      setWalletBalance(response.data.Response)
      setIsLoading(false);
    })
    .catch(error => {
      console.log(error);
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="widgetGold">
      {!isLoading ?
      <div className="left">
        <span className="title">Total Gold Balance</span>
        <span className="goldweight">
        {walletBalance.goldBalance} gms
        </span>
        <span className="goldamount">₹ {walletBalance.ValueInRupees}</span>
      </div>
      :
      <div className="spinner"></div>
      }
    </div>
  );
};

export default WalletGoldBalance;
