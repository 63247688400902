import "./navbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const user = useSelector(state => state.user);
  const isNotBrand = user.userType!='5';

  return (
    <div className="navbar">
      {isNotBrand ?
      <div className="wrapper">
          <div className="agencyName">
          {user.agencyName}
          </div>
          <div className="userName">
          Hi, {user.firstName} {user.lastName}
          </div>
      </div>
      :
      <div className="wrapper">
        <div className="agencyName">
          {user.agencyName}
        </div>
        <div className="agencyName">
          Howdy, {user.brandName}!
        </div>
      </div>
      }
    </div>
  );
};

export default Navbar;
