import "./newevent.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
// import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import {saveEndpoint} from '../../utils/services';
import {CREATE_EVENT} from '../../src-constants/Urls'
import { EVENT_TITLE_TEXT, EVENT_DESCRIPTION_TEXT, EVENT_AUTHOR_TEXT, EVENT_IMAGEURL_TEXT, EVENT_TYPE_OFFLINE, EVENT_TYPE_ONLINE, EVENT_TYPE_TEXT, EVENT_URL_TEXT, CANCEL_TEXT, ADD_TEXT, EVENT_START_DATE_TIME, EVENT_END_DATE_TIME } from "../../src-constants/Constants";
import { isValidURL, isValidImageURL, updateError, handleChangeDateTime } from "../../src-constants/Functions";
import {useNavigate} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useSelector } from "react-redux";

const NewEvent = ({ title }) => {
  const [eventTitle, setEventTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [eventURL, setEventURL] = useState("");
  const [author, setAuthor] = useState("");
  const [eventType, setEventType] = useState("Offline Meet");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [error, setError] = useState('');
  const token = useSelector(state => state.user.token);

  const navigate = useNavigate();

  const handleCancel = () => {navigate('/events');};

  const isValidForm = () => {
    if (eventTitle === "" || eventTitle === " ")
      return updateError('Required All Fields!', setError);
    if (description === "" || description === " ")
      return updateError('Required All Fields!', setError);
    if (author === "" || author === " ")
      return updateError('Required All Fields!', setError);
    if (eventType === "" || eventType === " ")
      return updateError('Required All Fields!', setError);
    if (startDateTime === "" || startDateTime === " ")
      return updateError('Required All Fields!', setError);
    if (endDateTime === "" || endDateTime === " ")
      return updateError('Required All Fields!', setError);
    else if (!isValidImageURL(imageURL))
      return updateError('Invalid Image URL!', setError);
    else if (!isValidURL(eventURL))
      return updateError('Invalid Event URL!', setError);
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isValidForm()) {
       
    const payload = {
      title: eventTitle,
      description: description,
      image: imageURL,
      url: eventURL,
      isNew: true,
      author: author,
      type: eventType,
      eventStartDateTime: startDateTime,
      eventEndDateTime: endDateTime,
    }
    console.log(payload)
    saveEndpoint(token, CREATE_EVENT, payload)
      .then(response => {
        navigate('/events');
      })
      .catch(error => {
        return updateError("Unable to add the Event!!", setError);
      });
    }
  }

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="heading">Add New Event</div>
        {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleSubmit}>
              
              <div className="formInput">
                <label>{EVENT_TITLE_TEXT}</label>
                <input type="text" placeholder="title" name="title" value={eventTitle || ""} onChange={(event) => setEventTitle(event.target.value)}/>
              </div>
              
              <div className="formInput">
                <label>{EVENT_DESCRIPTION_TEXT}</label>
                <input type="text" placeholder="description" name="description" value={description || ""} onChange={(event) => {setDescription(event.target.value)}}/>
              </div>
              
              <div className="formInput">
                <label>{EVENT_IMAGEURL_TEXT}</label>
                <input type="text" placeholder="enter image URL" name="imageURL" value={imageURL || ""} onChange={(event) => {setImageURL(event.target.value)}}/>
              </div>
              
              <div className="formInput">
                <label>{EVENT_URL_TEXT}</label>
                <input type="text" placeholder="enter event URL" name="eventURL" value={eventURL || ""} onChange={(event) => {setEventURL(event.target.value)}}/>
              </div>
              
              <div className="formInput">
                <label>{EVENT_AUTHOR_TEXT}</label>
                <input type="text" placeholder="enter author name" name="author" value={author || ""} onChange={(event) => {setAuthor(event.target.value)}}/>
              </div>
              
              <div className="formInput">
                <label>{EVENT_TYPE_TEXT}</label>
                <select eventType={eventType} onChange={(event) => {setEventType(event.target.value)}}>
                  <option value="Offline Meet">{EVENT_TYPE_OFFLINE}</option>
                  <option value="Online Meet">{EVENT_TYPE_ONLINE}</option>
                </select>
              </div>
              
              <div className="formInput">
                <label>{EVENT_START_DATE_TIME}</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="datetime"
                    renderInput={(props) => <TextField {...props} sx={{
                      svg: { color: "#000" },
                    }}/>}
                    value={startDateTime}
                    onChange={(newValue) => {
                      setStartDateTime(handleChangeDateTime(newValue));
                    }}
                    placeholder="mm-dd-yyyy hh:mm:ss"
                  />
                </LocalizationProvider>
              </div>
              
              <div className="formInput">
                <label>{EVENT_END_DATE_TIME}</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="datetime"
                    renderInput={(props) => <TextField {...props} sx={{
                      svg: { color: "#000" },
                    }}/>}
                    value={endDateTime}
                    onChange={(newValue) => {
                      setEndDateTime(handleChangeDateTime(newValue));
                    }}
                  />
                </LocalizationProvider>
              </div>

              <button>{ADD_TEXT}</button>

            </form>
          </div>
        </div>

        <div onClick={handleCancel} className="closeText">{CANCEL_TEXT}</div>

      </div>
    </div>
  );
};

export default NewEvent;
