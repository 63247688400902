import "./episodespeakerdetection.css";
import { useState, useEffect } from "react";
import {fetchEpisodeAnalytics} from '../../utils/services';
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const EpisodeSpeakerDetection = ({feedUrl, episodeGuid}) => {
  const [episodeSpeakerDetectionData, setEpisodeSpeakerDetectionData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [speakerNameValue, setSpeakerNameValue] = useState("");
  const user = useSelector(state => state.user);

  useEffect(() => {
    fetchEpisodeAnalytics(user.token, feedUrl, episodeGuid)
    .then(response => {
      setEpisodeSpeakerDetectionData(response.data.Response.returnData.speakerDetaction);
    })
    .catch(error => {console.log(error);});
  }, []);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    setSpeakerNameValue("");
  };

  const handleOnChangeText = (value, fieldName) => {
    setSpeakerNameValue({...speakerNameValue, [fieldName]: value});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("hello")
  }

  return (
    <>
    <div className="speakerListContainer">
      {/* { episodeSpeakerDetectionData.length ?
        <div className="addSpeakerNameContainer">
        <button onClick={handleOpen} className="addSpeakerNameButton">ADD SPEAKER NAME</button>
        </div>
      : 
        <></>
      } */}
      { episodeSpeakerDetectionData.length ?
        episodeSpeakerDetectionData.map((item) => (
        <div>
        <div className="speaker">SPEAKER {item.speaker}</div>
        <div className="speakerText">{item.text}</div>
        </div>
        ))
        : <div className="noKeywordList">No speaker detection available for this episode</div>
      }
    </div>

    <Modal
    open={isOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box className="boxStyle">
    {/* {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>} */}
      <div className="addTitle">Add Speaker Name</div>
      <form onSubmit={handleSubmit}>  

        <div className="formInput">
          <label>Speaker A</label>
          <input type="text" placeholder="Pranav" name="speakerNameValue" value={speakerNameValue || ""} onChange={(event) => {setSpeakerNameValue(event.target.value)}}/>
        </div>

        <div className="buttonsContainer">
        <button>Update</button>
        <div onClick={handleClose} className="closeText">Cancel</div>
        </div>

      </form>
    </Box>
    </Modal>
    </>
  );
};

export default EpisodeSpeakerDetection;
