import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Reset from './pages/login/Reset';
import Event from './pages/events/Event';
import FeaturedPodcast from './pages/featured-podcast/FeaturedPodcast';
import SpotlightPodcast from './pages/spotlight-podcast/SpotlightPodcast';
import './style/dark.scss';
import NewEvent from './pages/new-event/NewEvent';
import UpdateEvent from './pages/update-event/UpdateEvent';
import Agency from './pages/agency/Agency';
import Users from './pages/users/Users';
import FeaturedPodcasters from './pages/featured-podcasters/FeaturedPodcasters';
import Invitation from './pages/invitation/Invitation';
import PodcastDescription from './pages/podcast-description/PodcastDescription';
import Brands from './pages/brand/Brand';
import Transaction from './pages/transaction/Transaction';
import { useEffect } from 'react';

const App = () => {
  const user = useSelector((state) => state.user);
  const isLoggedIn = user && !!user.token;
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  const PrivateRoute = ({ path, roles, element: Element }) => {
    if (!isLoggedIn) {
      navigate('/login');
      return null;
    }

    if (
      currentPath !== '/reset' &&
      user.isUpdatePasswordRequired !== undefined &&
      user.isUpdatePasswordRequired
    ) {
      navigate('/reset');
      return null;
    }

    if (roles && !roles.includes(user.userType)) {
      navigate('/');
      return null;
    }

    return <Element />;
  };

  return (
    <Routes>
      <Route
        path="/login"
        element={isLoggedIn ? <Navigate to="/" /> : <Login />}
      />
      <Route
        path="/reset"
        element={
          <PrivateRoute
            roles={['admin', 'agency', 'podcaster', 'brand']}
            element={Reset}
          />
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute
            roles={['admin', 'agency', 'podcaster', 'brand']}
            element={Home}
          />
        }
      />
      <Route path="/events">
        <Route
          index
          element={<PrivateRoute roles={['admin']} element={Event} />}
        />
        <Route
          path="add"
          element={<PrivateRoute roles={['admin']} element={NewEvent} />}
        />
        <Route
          path="update"
          element={<PrivateRoute roles={['admin']} element={UpdateEvent} />}
        />
      </Route>
      <Route
        path="/podcast"
        element={
          <PrivateRoute roles={['admin']} element={PodcastDescription} />
        }
      />
      <Route
        path="/spotlight"
        element={<PrivateRoute roles={['admin']} element={SpotlightPodcast} />}
      />
      <Route
        path="/featured"
        element={<PrivateRoute roles={['admin']} element={FeaturedPodcast} />}
      />
      <Route
        path="/featured-podcasters"
        element={
          <PrivateRoute roles={['admin']} element={FeaturedPodcasters} />
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute
            roles={['admin', 'agency', 'agencyPodcaster', 'brand']}
            element={Users}
          />
        }
      />
      <Route
        path="/agency"
        element={<PrivateRoute roles={['admin', 'agency']} element={Agency} />}
      />
      <Route
        path="/invitation"
        element={
          <PrivateRoute roles={['admin', 'agency']} element={Invitation} />
        }
      />
      <Route
        path="/brands"
        element={<PrivateRoute roles={['admin', 'brand']} element={Brands} />}
      />
      <Route
        path="/transaction"
        element={<PrivateRoute roles={['admin']} element={Transaction} />}
      />
    </Routes>
  );
};

export default App;
