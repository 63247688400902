import objectAssign from 'object-assign';

const ADD_USER = 'ADD_USER';
const DELETE_USER = 'DELETE_USER';
const UPDATE_USER = 'UPDATE_USER';

const initialState = {
  user: {},
};

const userType = {
  1: 'podcaster',
  2: 'admin',
  3: 'agency',
  4: 'agencyPodcaster',
  5: 'brand',
};

export const addUser = (user) => {
  return (dispatch) => {
    dispatch({ type: ADD_USER, user });
  };
};

export const updateUser = (user) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER, user });
  };
};

export const deleteUser = (user) => {
  return (dispatch) => {
    dispatch({ type: DELETE_USER, user });
  };
};

export default function profileReducer(state = initialState.user, action) {
  switch (action.type) {
    case ADD_USER:
      return objectAssign({}, state, {
        ...action.user,
        userType: userType[action.user.userType],
      });
    case UPDATE_USER:
      return objectAssign({}, state, {
        ...state,
        ...action.user,
        // userType: userType[action.user.userType],
      });
    case DELETE_USER:
      return initialState.user;
    default:
      return state;
  }
}
