import "./featureddatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { featuredColumns } from "../../datatablesource";
import { useState, useEffect } from "react";
import {getEndpoint, fetchPodcastData, saveEndpoint, updatePodcastEndpoint} from '../../utils/services';
import {GET_FEATURED, CREATE_ADMIN_FEATURED, UPDATE_ADMIN_FEATURED} from '../../src-constants/Urls'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { ADD_TEXT, CANCEL_TEXT, DELETE_TEXT, FEATURED_ADD_HEADING, FEATURED_HEADING, FEATURED_PODCAST_URL, FETCH_TEXT, PODCAST_AUTHOR_TEXT, PODCAST_DESCRIPTION_TEXT, PODCAST_IMAGEURL_TEXT, PODCAST_OWNERNAME_TEXT, PODCAST_TITLE_TEXT, UPDATE_PODCAST_TO_SPOTLIGHT_LIST, UPDATE_TEXT } from "../../src-constants/Constants";
import { isValidImageURL, isValidURL, updateError, generateRandom } from "../../src-constants/Functions";
import { useSelector } from "react-redux";

const FeaturedDatatable = () => {
  const [data, setData] = useState([{_id: "", title: "", description: "", image: "", url: "", author: "", ownerName: "", createdAt: "", updatedAt: "", imagedisplay: ""}]);
  const [podcastURL, setPodcastURL] = useState("");
  const [error, setError] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [refPodcastId, setRefPodcastId] = useState("");
  const token = useSelector(state => state.user.token);

  const initialState = () => {
    setPodcastURL("");
    setPodcastTitle("");
    setPodcastDescription("");
    setPodcastImageURL("");
    setPodcastAuthor("");
    setPodcastOwnerName("");
  };
  
  const handleOpen = () => setIsOpen(true);
  const handleOpenUpdate = (params) => {
    setIsOpenUpdate(true)
    setPodcastTitle(params.row.title);
    setPodcastDescription(params.row.description);
    setPodcastImageURL(params.row.image);
    setPodcastAuthor(params.row.author);
    setPodcastOwnerName(params.row.ownerName);
    setPodcastURL(params.row.url);
    setRefPodcastId(params.row._id);
  };

  const handleClose = () => {
    setIsOpen(false); 
    initialState();
  };
  const handleCloseUpdate = () => {
    setIsOpenUpdate(false);
    initialState();
    setRefPodcastId("");
  };

  const handleDeletePodcast = (params) => {
    const payloadDeletePodcast = {
      title: params.row.title,
      description: params.row.description,
      image: params.row.image,
      author: params.row.author,
      url: params.row.url,
      ownerName: params.row.ownerName,
      status: "InActive",
    }
    updatePodcastEndpoint(token, UPDATE_ADMIN_FEATURED, params.row._id, payloadDeletePodcast)
    .then(response => {
      setData(data.filter((item) => item._id !== params.row._id));
    })
    .catch(error => {        
      return updateError("Unable to delete the podcast!!", setError);
    });
  };

  useEffect(() => {
    getEndpoint(token, GET_FEATURED)
    .then(response => {
      setData(response.data.Response)
    })
    .catch(error => {
      return updateError("Unable to retrieve the data!!", setError);
    });
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div 
              onClick={() => {handleOpenUpdate(params)}} 
              className="viewButton">{UPDATE_TEXT}</div>
            <div
              className="deleteButton"
              onClick={() => {
                handleDeletePodcast(params)
              }}
            >
              {DELETE_TEXT}
            </div>
          </div>
        );
      },
    },
  ];

  const isValidForm = () => {
    if (!isValidURL(podcastURL))
      return updateError('Invalid Podcast URL!', setError);
    return true;
  };

  const fetchDetails = (event) => {
    event.preventDefault();
    if (isValidForm()) {
      fetchPodcastData(token, podcastURL)
      .then(response => {
        setPodcastTitle(response.data.Response.title);
        setPodcastDescription(response.data.Response.description);
        setPodcastImageURL(response.data.Response.podcastImage);
        setPodcastAuthor(response.data.Response.itunesInfo.author);
        setPodcastOwnerName(response.data.Response.itunesInfo.owner.name);
      })
      .catch(error => {
        initialState();
        return updateError('No podcast data exists for URL!', setError);
      });
    }
  }

  const [podcastTitle, setPodcastTitle] = useState("");
  const [podcastDescription, setPodcastDescription] = useState("");
  const [podcastImageURL, setPodcastImageURL] = useState("");
  const [podcastAuthor, setPodcastAuthor] = useState("");
  const [podcastOwnerName, setPodcastOwnerName] = useState("");

  const isValidFormAdd = () => {
    if (podcastTitle === "" || podcastTitle === " ")  
      return updateError('Details Missing!!!', setError)
    if (podcastDescription === "" || podcastDescription === " ")  
      return updateError('Details Missing!!!', setError)  
    // if (podcastAuthor === "" || podcastAuthor === " ")  
    //   return updateError('Details Missing!!!', setError)
    // if (podcastOwnerName === "" || podcastOwnerName === " ")  
    //   return updateError('Details Missing!!!', setError)
    if (!isValidImageURL(podcastImageURL))
      return updateError('Invalid Image URL!', setError);      
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValidFormAdd()) {
      const payloadAddPodcast = {
        title: podcastTitle,
        description: podcastDescription,
        podcastImage: podcastImageURL,
        author: podcastAuthor,
        url: podcastURL,
        ownerName: podcastOwnerName,
      }
      saveEndpoint(token, CREATE_ADMIN_FEATURED, payloadAddPodcast)
      .then(response => {
        getEndpoint(token, GET_FEATURED)
        .then(response => {
          setData(response.data.Response)
          setIsOpen(false);
          initialState();
        })
        .catch(error => {
          return updateError("Unable to retrieve the data!!", setError);
        });
      })
      .catch(error => {
        return updateError("Unable to add podcast!!", setError);
      });
    }
  }

  const isValidFormUpdate = () => {
    if (podcastTitle === "" || podcastTitle === " ")  
      return updateError('Details Missing!!!', setError)
    if (podcastDescription === "" || podcastDescription === " ")  
      return updateError('Details Missing!!!', setError)  
    // if (podcastAuthor === "" || podcastAuthor === " ")  
    //   return updateError('Details Missing!!!', setError)
    // if (podcastOwnerName === "" || podcastOwnerName === " ")  
    //   return updateError('Details Missing!!!', setError)    
    return true;
  };

  const handleSubmitUpdate = (event) => {
    event.preventDefault();
    if (isValidFormUpdate()) {
      const payloadUpdatePodcast = {
        title: podcastTitle,
        description: podcastDescription,
        image: podcastImageURL,
        author: podcastAuthor,
        url: podcastURL,
        ownerName: podcastOwnerName,
        status: "Active",
      }
      updatePodcastEndpoint(token, UPDATE_ADMIN_FEATURED, refPodcastId, payloadUpdatePodcast)
      .then(response => {
        getEndpoint(token, GET_FEATURED)
        .then(response => {
          setData(response.data.feeds)
          setIsOpenUpdate(false);
          initialState();
        })
        .catch(error => {
          return updateError("Unable to retrieve the data!!", setError);
        });
      })
      .catch(error => {
        return updateError("Unable to update the podcast!!", setError);
      });
    }
  }

  return (
    <>
    <div className="datatable">
      {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
      <div className="datatableTitle">
        {FEATURED_HEADING}
        <Button onClick={handleOpen} className="link">{FEATURED_ADD_HEADING}</Button>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={featuredColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) =>  generateRandom()}
      />
    </div>

    <Modal
    open={isOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box className="boxStyle">
    {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
      <div className="addTitle">{FEATURED_ADD_HEADING}</div>
      <form onSubmit={handleSubmit}>  
        <div className="formInput">
          <label>{FEATURED_PODCAST_URL}</label>
          <input type="text" placeholder="URL" name="url" value={podcastURL || ""} onChange={(event) => {setPodcastURL(event.target.value)}}/>
        </div>
        
        <div className="buttonsContainer">
        <button onClick={fetchDetails}>{FETCH_TEXT}</button>
        </div>

        <div>
        <div className="formInput">
          <label>{PODCAST_TITLE_TEXT}</label>
          <input type="text" placeholder="title" name="title" value={podcastTitle || ""} onChange={(event) => {setPodcastTitle(event.target.value)}}/>
        </div>

        <div className="formInput">
          <label>{PODCAST_DESCRIPTION_TEXT}</label>
          <input type="text" placeholder="description" name="description" value={podcastDescription || ""} onChange={(event) => {setPodcastDescription(event.target.value)}}/>
        </div>

        <div className="formInput">
          <label>{PODCAST_AUTHOR_TEXT}</label>
          <input type="text" placeholder="author" name="author" value={podcastAuthor || ""} onChange={(event) => {setPodcastAuthor(event.target.value)}}/>
        </div>

        <div className="formInput">
          <label>{PODCAST_IMAGEURL_TEXT}</label>
          <input type="text" placeholder="image url" name="imageurl" value={podcastImageURL || ""} onChange={(event) => {setPodcastImageURL(event.target.value)}}/>
        </div>

        <div className="formInput">
          <label>{PODCAST_OWNERNAME_TEXT}</label>
          <input type="text" placeholder="owner name" name="ownername" value={podcastOwnerName || ""} onChange={(event) => {setPodcastOwnerName(event.target.value)}}/>
        </div>

        <div className="buttonsContainer">
        <button>{ADD_TEXT}</button>
        <div onClick={handleClose} className="closeText">{CANCEL_TEXT}</div>
        </div>
        </div>

      </form>
    </Box>
    </Modal>

    <Modal
      open={isOpenUpdate}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
      <Box className="boxStyle">
      {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
        <div className="addTitle">{UPDATE_PODCAST_TO_SPOTLIGHT_LIST}</div>
        <form onSubmit={handleSubmitUpdate}>  
          <div className="formInput">
            <label>{PODCAST_TITLE_TEXT}</label>
            <input type="text" placeholder="title" name="title" value={podcastTitle || ""} onChange={(event) => {setPodcastTitle(event.target.value)}}/>
          </div>

          <div className="formInput">
            <label>{PODCAST_DESCRIPTION_TEXT}</label>
            <input type="text" placeholder="description" name="description" value={podcastDescription || ""} onChange={(event) => {setPodcastDescription(event.target.value)}}/>
          </div>

          <div className="formInput">
            <label>{PODCAST_AUTHOR_TEXT}</label>
            <input type="text" placeholder="author" name="author" value={podcastAuthor || ""} onChange={(event) => {setPodcastAuthor(event.target.value)}}/>
          </div>

          <div className="formInput">
            <label>{PODCAST_IMAGEURL_TEXT}</label>
            <input type="text" placeholder="image url" name="imageurl" value={podcastImageURL || ""} onChange={(event) => {setPodcastImageURL(event.target.value)}}/>
          </div>

          <div className="formInput">
            <label>{PODCAST_OWNERNAME_TEXT}</label>
            <input type="text" placeholder="owner name" name="ownername" value={podcastOwnerName || ""} onChange={(event) => {setPodcastOwnerName(event.target.value)}}/>
          </div>

          <div className="buttonsContainer">
          <button>{UPDATE_TEXT}</button>
          <div onClick={handleCloseUpdate} className="closeText">{CANCEL_TEXT}</div>
          </div>
        </form>
      </Box>
    </Modal>
    </>
  );
};

export default FeaturedDatatable;
