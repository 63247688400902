import "./eventsdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { eventColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import {getEndpoint, updateEndpoint} from '../../utils/services';
import {GET_EVENT, UPDATE_EVENT} from '../../src-constants/Urls'
import { ADD_NEW_EVENT, UPDATE_TEXT, DELETE_TEXT,EVENTS_HEADING } from "../../src-constants/Constants";
import { updateError, generateRandom } from "../../src-constants/Functions";
import { useSelector } from "react-redux";

const EventsDatatable = () => {
  const [data, setData] = useState([{_id: "", title: "", description: "", image: "", url: "", author: "", type: "", eventStartDate: "", eventStartTime: "", eventEndDate: "", eventEndTime: ""}]);
  const [error, setError] = useState('');
  const user = useSelector(state => state.user);

  const handleDelete = (params) => {
    const deletePayload = {
      title: params.row.title,
      description: params.row.description,
      image: params.row.image,
      url: params.row.url,
      isNew: true,
      author: params.row.author,
      type: params.row.type,
      eventStartDateTime: params.row.eventStartDateTimeIn24Hours,
      eventEndDateTime: params.row.eventEndDateTimeIn24Hours, 
      status: "Inactive",
    }
    updateEndpoint(user.token, UPDATE_EVENT, params.row._id, deletePayload)
      .then(response => {
        setData(data.filter((item) => item._id !== params.row._id));
      })
      .catch(error => {
        return updateError(error, setError);
      });
  };

  useEffect(() => {
    getEndpoint(user.token, GET_EVENT)
    .then(response => {
      setData(response.data.response)
    })
    .catch(error => {
      return updateError("Unable to retrieve the data!!", setError);
    });
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to="/events/update" style={{ textDecoration: "none" }} state={{ 
              routeId : params.row._id,
              routeTitle : params.row.title,
              routeDescription : params.row.description, 
              routeImageURL : params.row.image, 
              routeEventURL : params.row.url, 
              routeAuthor : params.row.author, 
              routeEventType : params.row.type, 
              routeStartDateTime : params.row.eventStartDateTimeIn24Hours, 
              routeEndDateTime : params.row.eventEndDateTimeIn24Hours,
              }}>
              <div className="viewButton">{UPDATE_TEXT}</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => {handleDelete(params)}}
            >
              {DELETE_TEXT}
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
      <div className="datatableTitle">
        {EVENTS_HEADING}
        <Link to="/events/add" className="link">
          {ADD_NEW_EVENT}
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={eventColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) =>  generateRandom()}
      />
    </div>
  );
};

export default EventsDatatable;
