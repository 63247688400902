import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Pagination from '../pagination/Pagination';
import { getEndpoint } from '../../utils/services';
import { GET_BOOSTED_LIST } from '../../src-constants/Urls';
import './boostedpodcastlist.css';

const PageSize = 5;

const BoostedPodcastList = () => {
  const [boostedList, setBoostedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.user);

  useEffect(() => {
    const fetchBoostedList = async () => {
      setIsLoading(true);

      try {
        const response = await getEndpoint(user.token, GET_BOOSTED_LIST);
        setBoostedList(response.data.Response);
      } catch (error) {
        console.log(error);
      }
      finally{
        setIsLoading(false);
      }

    };

    fetchBoostedList();
  }, [user.token]);

  const renderBoostedPodcastList = () => {
    if (isLoading) {
      return <div className="spinner"></div>;
    }

    if (boostedList.length === 0) {
      return <div className="noBoostedList">No recent boosted podcast</div>;
    }

    const slicedBoostedList = boostedList.slice((currentPage - 1) * PageSize, currentPage * PageSize);

    return (
      <>
        {slicedBoostedList.map(({ feedUrl, feedImageUrl, podcastTitle, podcastAuthor, episodeTitle, senderInfo, goldWeight, goldPrice }, index) => (
          <div key={index}>
            <Link to='/podcast' state={{'routeUrl': feedUrl}} className="linkPodcast">
              <div className="boostedListItem">
                <img src={feedImageUrl} alt="" className="imageMain" />
                <div className="boostedListItemInfo">
                  <div className="podcastName">{podcastTitle}</div>
                  <div className="podcastAuthor">{podcastAuthor}</div>
                </div>
                <div className="boostedListItemInfo">
                  <div className="episodeNumber">{episodeTitle}</div>
                  <div className="episodeName">
                    Recent Boosted Podcasts
                  </div>
                </div>
                <div className="boostedItemListGoldInfo">
                  <AccountCircleIcon className="imageSecondary" />
                  <div className="boostedListItemInfo">
                    <div className="usernameText">{senderInfo.firstName} {senderInfo.lastName}</div>
                    <div className="sentYouText">sent you</div>
                  </div>
                  <div>
                    <div className="goldWeightText">{goldWeight} gms</div>
                    <div className="goldRateText">~ ₹ {goldPrice}</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
        <Pagination
          key="pagination"
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={boostedList.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </>
    );
  };

  return <div>{renderBoostedPodcastList()}</div>;
};

export default BoostedPodcastList;
