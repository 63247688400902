import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./podcastdescription.css";
import logo from '../../images/podcast-logo.svg';
import { Link } from "react-router-dom";
import WalletGoldBalance from "../../components/wallet-gold-balance/WalletGoldBalance";
import PodcastGoldBalance from "../../components/podcast-gold-balance/PodcastGoldBalance";
import { useState, useEffect, useCallback } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import playIcon from '../../images/playIcon.svg';
import ReactAudioPlayer from 'react-audio-player';
import CloseIcon from '@mui/icons-material/Close';
import EpisodeTranscription from "../../components/episode-transcription/EpisodeTranscription";
import EpisodeCategory from "../../components/episode-category/EpisodeCategory";
import EpisodeKeyword from "../../components/episode-keyword/EpisodeKeyword";
import EpisodeSpeakerDetection from "../../components/episode-speaker-detection/EpisodeSpeakerDetection";
import EpisodeBrandMentions from "../../components/episode-brand-mentions/EpisodeBrandMentions";
import {fetchPodcastAndEpisodeData, fetchBoostQuantity} from '../../utils/services';
import { useSelector } from "react-redux";
import {useNavigate, useLocation} from 'react-router-dom';
import Pagination from '../../components/pagination/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

let PageSize = 1;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const PodcastDescription = () => {
  const [podcastData, setPodcastData] = useState("");
  const [episodeData, setEpisodeData] = useState([]);
  const [podcastGeneralCategories, setPodcastGeneralCategories] = useState([]);
  const [podcastBoostedValues, setPodcastBoostedValues] = useState(0);
  const [podcastBoostDuration, setPodcastBoostDuration] = useState('1H');
  const [currentPage, setCurrentPage] = useState(1);
  const [podcastBalanceInGrams, setPodcastBalanceInGrams] = useState("");
  const [podcastBalanceInRupees, setPodcastBalanceInRupees] = useState("");

  const [isPlaying, setIsPlaying] = useState(false);
  const user = useSelector(state => state.user);
  let location = useLocation();
  console.log('location', location)
  console.log('podcastData', podcastData)
  const isAdmin = user.userType=='admin';
  const isAgency = user.userType=='agency';

  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleFetchBoostQuantity = useCallback(async () => {
    if(podcastData.yguuid === undefined)
      return false
    try {
      const boostResponse = await fetchBoostQuantity(user.token, podcastData.yguuid, String(podcastBoostDuration));
      setPodcastBoostedValues(boostResponse.data.Response);
    } catch (error) {
      console.log(error);
    }
  }, [user.token, podcastData.yguuid, podcastBoostDuration]);

  const handleFetchPodcastData = useCallback(async () => {
    try {
      const response = await fetchPodcastAndEpisodeData(user.token, location.state.routeUrl);
      const { podcastInfo, episodes, totalAmountRecieved, totalGoldRecieved } = response.data.Response;
  
      setPodcastData(podcastInfo);
      setEpisodeData(episodes);
      setPodcastGeneralCategories(podcastInfo.podcastCategories);
      setPodcastBalanceInRupees(totalAmountRecieved);
      setPodcastBalanceInGrams(totalGoldRecieved);
  
    } catch (error) {
      console.log(error);
    }
  }, [user.token, location.state.routeUrl]);
  
  
  useEffect(() => {
    handleFetchBoostQuantity();
  }, [handleFetchBoostQuantity]);

  useEffect(() => {
    handleFetchPodcastData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleSelect = (
    event,
    newSelection,
  ) => {
    if(newSelection==null){
      setPodcastBoostDuration('1H')
    }else{
      setPodcastBoostDuration(newSelection);
    }
  };

  const buttonTheme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#01579b",
        contrastText: '#ffcc00',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#11cb5f',
        darker: '#053e85',
      },
    },
  });

  const handleOpen = () => {
    setIsPlaying(true);
  };
  const handleClose = () => {
    setIsPlaying(false);
  };
  
  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableEpisodeData = episodeData.slice(firstPageIndex, lastPageIndex);

  return (
    <>
    <div className="podcastDescriptionMainContainer">
      <Sidebar />
      <div className="podcastDescriptionContainer">
        <Navbar />

        <Link to="/">
          <ArrowBackIcon sx={{ color: "white" }} className="backArrow"/>
        </Link>

        <div className="podcastListItem">

          <div className="podcastImageContainer">
          <img src={podcastData.podcastImage} alt="" className="imagePodcast" />
          <div className="totalEpisodeText">Total Episodes: {episodeData.length}</div>
          {/* <div className="totalEpisodeText">Total Plays: 354000</div> */}
          </div>
          
          <div className="podcastListItemInfo">
            <div className="podcastName">
              {podcastData.title}  
            </div>
            <div className="podcastAuthor">
              {podcastData.ownerName}  
            </div>
            <div className="podcastDescriptionText">
              {podcastData.description}
            </div>
            <div className="podcastCategoryListContainer">
            {podcastGeneralCategories.map((item) => (
              <div className="podcastCategoryList">{item}</div>
            ))}
            </div>
          </div>
          
          <div className="podcastListItemGoldInfo">
          {isAgency ?
          <PodcastGoldBalance balanceInGrams={podcastBalanceInGrams} balanceInRupees={podcastBalanceInRupees}/>
          :
          <></>
          }
          
          <div className="boostRecieved">
            
            <div>
            <div className="boostRecievedText">Boost Recieved</div>
            <div className="goldValueInDuration">{podcastBoostedValues.boost}</div>
            </div>

            <div className="boostRecievedDuration">
            <ThemeProvider theme={buttonTheme}>
            <ToggleButtonGroup
              color="primary"
              value={podcastBoostDuration}
              exclusive
              onChange={handleSelect}
              sx={[{'.Mui-disabled':{color : "white"}}]}
            >
              <ToggleButton value="1H">
                <div>1H</div>
              </ToggleButton>
              <ToggleButton value="1D">
                <div>1D</div>
              </ToggleButton>
              <ToggleButton value="1W">
                <div>1W</div>
              </ToggleButton>
              <ToggleButton value="1M">
                <div>1M</div>
              </ToggleButton>
              <ToggleButton value="all">
                <div>All</div>
              </ToggleButton>
            </ToggleButtonGroup>
            </ThemeProvider>
            </div>
          </div>

          </div>    
      </div>

      <div className="searchListItem">
        <div className="allepisodesText">All Episodes</div>
        {/* <div className="search">
          <input className="inputFieldEpisode" type="text" placeholder="Search for the episode here..." />
          <SearchOutlinedIcon />
        </div> */}
      </div>

      <Pagination
      className="paginationBarContainer"
      currentPage={currentPage}
      totalCount={episodeData.length}
      pageSize={PageSize}
      onPageChange={page => setCurrentPage(page)}
      />
      {currentTableEpisodeData.map((episodeItem) => (
      <div>

      <div className="epsiodeDataContainer">
      
        <div className="episodeListItem">  
          <div className="podcastListItemInfo">
          <div className="episodeNameText">
            {episodeItem.title}  
          </div>
          <div className="episodePublishDate">
            {episodeItem.episodePublishDate}  
          </div>
          <div className="episodeDescriptionText">
            {episodeItem.description}  
          </div>
          <div>
            {isPlaying ?
            <div className="playerContainer">
            <ReactAudioPlayer src={episodeItem.enclosureUrl} autoPlay controls/>
            <CloseIcon className="iconClose" onClick={handleClose} />
            </div>
            :<></>}
          </div>
          </div>

          <div onClick={handleOpen}>
          <div className="episodeListItemInfo">
            <div className="buttonPlay">
            <img src={playIcon} alt="" className="imagePlay" />
            </div>
            <div className="episodeTime">
            Duration : {episodeItem.durationInHrs}
            </div>
          </div>
          </div>
        </div>

        <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        className="tabsContainer"
        >
          <Tab label="Transcription" {...a11yProps(0)} key="Transcription" />
          <Tab label="Speaker Detection" {...a11yProps(1)} key="Speaker Detection" />
          <Tab label="Keywords" {...a11yProps(2)} key="Keywords" />
          <Tab label="Detected Topics" {...a11yProps(3)} key="Detected Topics" />
          {isAdmin ?
          <Tab label="Brand Mentions" {...a11yProps(4)} key="Brand Mentions" />
          :<></>}
        </Tabs>
      </div>

      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <EpisodeTranscription feedUrl={episodeItem.feedUrl} episodeGuid={episodeItem.guid} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <EpisodeSpeakerDetection feedUrl={episodeItem.feedUrl} episodeGuid={episodeItem.guid} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <EpisodeKeyword feedUrl={episodeItem.feedUrl} episodeGuid={episodeItem.guid} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <EpisodeCategory feedUrl={episodeItem.feedUrl} episodeGuid={episodeItem.guid} />
        </TabPanel>
        {isAdmin ?
        <TabPanel value={value} index={4} dir={theme.direction}>
          <EpisodeBrandMentions feedUrl={episodeItem.feedUrl} episodeGuid={episodeItem.guid} />
        </TabPanel>
        :<></>}
      </SwipeableViews>

      </div>
      ))} 
      
      </div>
      {/* <div className="audioContainer">
      <div className="audioPlayer">
      <div className="podcastAudioText">Story Tellers and story sellers</div> 
      <div className="episodeAudioText"> Episode 1 : What in the world of finance with Sharan</div>
      <ReactAudioPlayer className="player" src={PodcastEpisodeDefault} autoPlay controls/>
      </div>
      </div> */}
    </div>
    </>
  );
};

export default PodcastDescription;
