export const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzNjM5NjY4ODBkZmQ4YWMzMzEwYWFmNCIsImVtYWlsIjoiYWxva3JhbmphbjAxN0BnbWFpbC5jb20iLCJ1c2VyVHlwZSI6ImFkbWluIiwiaWF0IjoxNjY5ODEzMTkwLCJleHAiOjMzNDgyNjYzODAsImlzcyI6IklvVGVyYWN0aW9ucyBQdnQgTHRkIn0.OP2gEL31i0kP5gxHzdwJXHWF_atilm0yCJi50L7kz3c";
export const UPDATE_TEXT = 'UPDATE'
export const DELETE_TEXT = 'DELETE'
export const ADD_TEXT = 'ADD'
export const CANCEL_TEXT = 'Cancel'
export const FETCH_TEXT = 'Fetch'
export const EVENTS_HEADING = 'Events'
export const ADD_NEW_EVENT = 'ADD NEW EVENT'

export const EVENT_TITLE_TEXT = 'Event Title'
export const EVENT_DESCRIPTION_TEXT = 'Event Description'
export const EVENT_IMAGEURL_TEXT = 'Image URL'
export const EVENT_URL_TEXT = 'Event URL'
export const EVENT_AUTHOR_TEXT = 'Author'
export const EVENT_TYPE_TEXT = 'Event Type'
export const EVENT_TYPE_OFFLINE = 'Offline Meet'
export const EVENT_TYPE_ONLINE = 'Online Meet'
export const EVENT_START_DATE_TIME = 'Event Start Date & Time (MM-DD-YYYY HH:MM:SS)'
export const EVENT_END_DATE_TIME = 'Event End Date & Time (MM-DD-YYYY HH:MM:SS)'

export const FEATURED_HEADING = 'Featured Podcasts'
export const FEATURED_ADD_HEADING = 'Add New Featured Podcast'
export const SPOTLIGHT_HEADING = 'Spotlight Podcasts'
export const SPOTLIGHT_ADD_HEADING = 'Add New Spotlight Podcast'
export const RECOMMENDED_HEADING = 'Recommended Podcasts'
export const RECOMMENDED_ADD_HEADING = 'Add Recommended Podcast'

export const PODCAST_TITLE_TEXT = 'Title'
export const PODCAST_DESCRIPTION_TEXT = 'Description'
export const PODCAST_AUTHOR_TEXT = 'Author'
export const PODCAST_ID_TEXT = 'Podcast ID'
export const PODCAST_IMAGEURL_TEXT = 'Image URL'
export const PODCAST_OWNERNAME_TEXT = 'Owner Name'

export const UPDATE_PODCAST_TO_SPOTLIGHT_LIST = 'UPDATE PODCAST TO SPOTLIGHT LIST'
export const UPDATE_PODCAST_TO_FEATURED_LIST = 'UPDATE PODCAST TO FEATURED LIST'
export const UPDATE_PODCAST_TO_RECOMMENDED_LIST = 'UPDATE PODCAST TO RECOMMENDED LIST'

export const FEATURED_PODCAST_URL = 'Featured Podcast URL'
export const RECOMMEDED_PODCAST_URL = 'Recommended Podcast URL'
export const SPOTLIGHT_PODCAST_URL = 'Spotlight Podcast URL'

export const USER_FIRST_NAME_TEXT = 'First Name'
export const USER_LAST_NAME_TEXT = 'Last Name'
export const USER_EMAIL_TEXT = 'Email'
export const USER_TYPE_TEXT = 'User Type'
export const USER_SELECT_TEXT = 'Select'
// export const  = ''
// export const  = ''
// export const  = ''
// export const  = ''
// export const  = ''
// export const  = ''
// export const  = ''