import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { getEndpoint } from '../../utils/services';
import { GET_TRANSACTIONS } from '../../src-constants/Urls';
import './transaction.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';

const Transaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('sell');
  const user = useSelector((state) => state.user);

  const columns = [
    { field: 'id', headerName: 'ID', width: 120 },
    { field: 'date', headerName: 'Date', width: 180 },
    { field: 'name', headerName: 'Name', width: 120 },
    { field: 'tranType', headerName: 'Transaction Type', width: 140 },
    { field: 'amount', headerName: 'SafeGold Price', width: 120 },
    { field: 'upi', headerName: 'UPI ID', width: 120 },
    { field: 'cashfreeStatus', headerName: 'Cashfree Status', width: 120 },
    { field: 'cashfreeAmount', headerName: 'Cashfree Amount', width: 120 },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getEndpoint(user.token, GET_TRANSACTIONS, {
          transactionType: filter,
        });
        const res = response.data.Response;
        console.log('res', res);
        const transactions = res.map((item, index) => {
          const { userInfo, cashfreePaymentInfo, safegoldTransactionInfo } =
            item;
          return {
            id: safegoldTransactionInfo?.tx_id,
            date: safegoldTransactionInfo.transactionDateTime
              ? format(
                  new Date(safegoldTransactionInfo.transactionDateTime),
                  'dd-MM-yyyy HH:MM:SS'
                )
              : '',
            tranType: safegoldTransactionInfo?.transactionType ? safegoldTransactionInfo?.transactionType : 'No Transaction',
            amount: safegoldTransactionInfo?.goldPrice,
            name: `${userInfo.firstName} ${userInfo.lastName}`,
            upi: cashfreePaymentInfo?.upi_id,
            cashfreeStatus: cashfreePaymentInfo.paymentStatus ? cashfreePaymentInfo.paymentStatus : '',
            cashfreeAmount: cashfreePaymentInfo.amount ? cashfreePaymentInfo.amount : '',
          };
        });

        setTransactions(transactions);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [filter, user.token]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <div
          className="filter"
          style={{ textAlign: 'right' }}
        >
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              labelId="filter-label"
              id="filter-select"
              value={filter}
              onChange={handleFilterChange}
              sx={{ color: 'green', borderColor: 'green' }}
            >
              <MenuItem value="sell">Sell</MenuItem>
              <MenuItem value="buy">Buy</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="datatable">
          <DataGrid
            className="datagrid"
            rows={transactions}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row.id}
          />
        </div>
      </div>
    </div>
  );
};

export default Transaction;
