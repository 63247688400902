import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import BoostedPodcastList from "../../components/boosted-podcast-list/BoostedPodcastList";
import BrandedPodcastList from "../../components/brands-podcast-list/BrandedPodcastList";
import WalletGoldBalance from "../../components/wallet-gold-balance/WalletGoldBalance";
import { useSelector } from "react-redux";

const Home = () => {
  console.log('Home')
  const user = useSelector((state) => state.user);
  const { userType } = user;

  const renderContent = () => {
    switch (userType) {
      case "admin": // Admin user
        return (
          <div className="listContainer">
            <div className="listTitle">Recent Boosted Podcasts</div>
            <BoostedPodcastList />
          </div>
        );
      case "agency": // Agency user
        return (
          <div className="widgets">
            <WalletGoldBalance />
          </div>
        );
      case "brand": // Brand user
        return (
          <div className="listContainer">
            <div className="listTitle">Recent Mentioned Podcasts</div>
            <BrandedPodcastList />
          </div>
        );
      default: // Other user types
        return (
          <div className="listContainer">
            <div className="listTitle">Recent Boosted Podcasts</div>
            <BoostedPodcastList />
          </div>
        );
    }
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        {renderContent()}
      </div>
    </div>
  );
};

export default Home;
