import "./invitationdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { inviteColumns } from "../../datatablesource";
import { useState, useEffect } from "react";
import {getEndpoint, saveEndpoint, updateEndpoint} from '../../utils/services';
import { SEND_INVITE, FETCH_INVITATION_DATA, UPDATE_INVITE, DELETE_INVITE } from '../../src-constants/Urls'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { updateError, generateRandom, isValidURL, isValidField, isValidEmail } from "../../src-constants/Functions";
import { CANCEL_TEXT } from "../../src-constants/Constants";
import { useSelector } from "react-redux";

const InvitationDatable = () => {
  const token = useSelector(state => state.user.token);
  const initialState = {firstName: "", lastName: "", email: "", feedUrl: "", split: ""}
  const [fetchedInvitationData, setFetchedInviteData] = useState([{_id:"", firstName: "", lastName: "", email: "", feedUrl: "", split: "", agencyId: ""}]);
  const [invitationData, setInvitationData] = useState([initialState]);
  const {firstName, lastName, email, feedUrl, split} = invitationData;
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const [inviteID, setInviteID] = useState('');
  const [isUpdating, setIsUpdating] = useState(false)
  const [agencySplit, setAgencySplit] = useState('100')

  const fetchInviteDataRequest = (token, FETCH_INVITATION_URL) => {
    getEndpoint(token, FETCH_INVITATION_URL)
    .then(res => {
      setFetchedInviteData(res.data.Response)
    })
    .catch(error => { return updateError(error.res.data.Message, setError); })
  }

  useEffect(() => {
    fetchInviteDataRequest(token, FETCH_INVITATION_DATA);
  }, []);

  const handleOnChangeText = (value, fieldName) => {
    setInvitationData({...invitationData, [fieldName]: value});
    if(fieldName === 'split'){
      const agencySplitValue = 100 - value;
      setAgencySplit(agencySplitValue);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setAgencySplit('100')
    setInvitationData(initialState)
  };

  const isValidForm = () => {
    if ( isValidField(firstName) || isValidField(lastName)) 
      return updateError('First or Last Missing', setError);
    if (!isValidURL(feedUrl))
      return updateError('Feed URL is incorrect!', setError);
    if (!isValidEmail(email))
      return updateError('Please enter correct email!', setError);
    if (isNaN(split) || split>100)
      return updateError('Please enter correct percentage', setError);  
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValidForm()) {
      const sendInvitationPayload = { feedUrl, firstName, lastName, email, split }
      saveEndpoint(token, SEND_INVITE, sendInvitationPayload)
      .then((response) => {
        setIsOpen(false);
        setInvitationData(initialState)
        setAgencySplit('100')
        fetchInviteDataRequest(token, FETCH_INVITATION_DATA);
      })
      .catch(error => { return updateError(error.response.data.Message, setError); })
    }
  }

  const handleOpenUpdate = (params) => {
    setIsUpdating(true)
    setIsOpen(true)
    setInviteID(params.row._id)
    setInvitationData({
      firstName: params.row.firstName, 
      lastName: params.row.lastName, 
      email: params.row.email, 
      feedUrl: params.row.feedUrl, 
      split: params.row.split,
    }) 
  };

  const handleCloseUpdate = () => {
    setIsUpdating(false)
    setIsOpen(false);
    setInvitationData(initialState)
    setAgencySplit('100')
  };
  
  const handleDeleteInvite = (params) => {
    const payload = {}
    updateEndpoint(token, DELETE_INVITE, params.row._id, payload)
    .then((response) => {
      setFetchedInviteData(fetchedInvitationData.filter((item) => item._id !== params.row._id))
    })
    .catch(error => { return updateError(error.response.data.Message, setError); })
  };

  const handleUpdateInvite = (event) => {
    event.preventDefault();
    if (isValidForm()) {
      const payload = { split }

      updateEndpoint(token, UPDATE_INVITE, inviteID, payload)
      .then((response) => {
        setIsUpdating(false);
        setIsOpen(false);
        setInvitationData(initialState)
        setAgencySplit('100')
        fetchInviteDataRequest(token, FETCH_INVITATION_DATA);
      })
      .catch(error => { return updateError(error.response.data.Message, setError); })
    }
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
              <div 
              onClick={() => {handleOpenUpdate(params)}} 
              className="viewButton">Update</div>
            <div
              className="deleteButton"
              onClick={() => {
                handleDeleteInvite(params)
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
    <div className="datatable">
      {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
      <div className="datatableTitle">
        Invitation
        <Button onClick={() => setIsOpen(true)} className="link">Send Invite</Button>
      </div>
      <DataGrid
        className="datagrid"
        rows={fetchedInvitationData}
        columns={inviteColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) =>  generateRandom()}
      />
    </div>
    
    <Modal
    open={isOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box className="boxStyle">
    {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
      <div className="addTitle">{isUpdating ? 'Update' : 'Add'} Invitation</div>
      <form onSubmit={isUpdating ? handleUpdateInvite : handleSubmit}>  
        
        {!isUpdating ?

        <>
        <div className="formInput">
          <label>Feed Url</label>
          <input type="text" placeholder="https://podcasturl.com" name="feedUrl" value={feedUrl || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'feedUrl')}}/>
        </div>

        <div className="formInput">
          <label>First Name</label>
          <input type="text" placeholder="John" name="firstName" value={firstName || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'firstName')}}/>
        </div>

        <div className="formInput">
          <label>Last Name</label>
          <input type="text" placeholder="Doe" name="lastName" value={lastName || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'lastName')}}/>
        </div>

        <div className="formInput">
          <label>Email</label>
          <input type="email" placeholder="abcd@email.com" name="email" value={email || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'email')}}/>
        </div>

        <div className="formInput">
          <label>Podcaster Split (%)</label>
          <input type="number" placeholder="split percentage" name="split" value={split || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'split')}}/>
        </div>

        <div className="formInput">
          <label>Agency Split (%)</label>
          <div className="agencySplit">{agencySplit}</div>
        </div>
        </>

        :

        <>
        <div className="formInput">
          <label>Podcaster Split (%)</label>
          <input type="number" placeholder="split percentage" name="split" value={split || ""}  onChange={(event) => {handleOnChangeText(event.target.value, 'split')}}/>
        </div>

        <div className="formInput">
        <label>Agency Split (%)</label>
        <div className="agencySplit">{agencySplit}</div>
        </div>
        </>

        }

        <div className="buttonsContainer">
        <button>{isUpdating? 'UPDATE' : 'ADD' }</button>
        <div onClick={isUpdating? handleCloseUpdate : handleClose} className="closeText">{CANCEL_TEXT}</div>
        </div>

      </form>
    </Box>
    </Modal>
    </>
  );
};

export default InvitationDatable;
