import "./agency.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import AgencyDatable from "../../components/agency-datatable/AgencyDatable"

const Agency = () => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <AgencyDatable/>
      </div>
    </div>
  )
}

export default Agency
