import React from 'react';
import './sidebar.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link } from 'react-router-dom';
import SupportAgent from '@mui/icons-material/SupportAgent';
import { useDispatch } from 'react-redux';
import { deleteUser } from '../../reducers/profileReducer';
import SendIcon from '@mui/icons-material/Send';
import logo from '../../images/yourgold-logo.svg';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AdjustIcon from '@mui/icons-material/Adjust';
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  console.log('user', user);

  const getMenuItems = () => {
    // Define your menu items based on roles
    const menuItems = {
      admin: [
        { label: 'Dashboard', path: '/', title: 'ADMIN', icon: DashboardIcon },
        { label: 'Events', path: '/events', icon: CreditCardIcon },
        { label: 'Spotlight Podcast', path: '/spotlight', icon: PodcastsIcon },
        { label: 'Featured Podcast', path: '/featured', icon: PodcastsIcon },
        { label: 'Transaction', path: '/transaction', icon: AdjustIcon },
        {
          label: 'Podcasters (Featured)',
          path: '/featured-podcasters',
          icon: SupportAgent,
        },
        { label: 'Brands', path: '/brands', title: 'BRANDS', icon: AdjustIcon },
        {
          label: 'Users',
          path: '/users',
          title: 'USERS',
          icon: PermIdentityIcon,
        },
        {
          label: 'Agency',
          path: '/agency',
          title: 'AGENCY',
          icon: SupportAgent,
        },
        { label: 'Invitation', path: '/invitation', icon: SendIcon },
        // { label: '', path: '', title: 'PROFILE', icon: ExitToAppIcon },
      ],
      agency: [
        { label: 'Dashboard', path: '/', title: 'ADMIN', icon: DashboardIcon },
        {
          label: 'Users',
          path: '/users',
          title: 'USERS',
          icon: PermIdentityIcon,
        },
        {
          label: 'Agency',
          path: '/agency',
          title: 'AGENCY',
          icon: SupportAgent,
        },
        { label: 'Invitation', path: '/invitation', icon: SendIcon },
        // { label: '', path: '', title: 'PROFILE', icon: ExitToAppIcon },
      ],
      agencyPodcaster: [
        { label: 'Dashboard', path: '/', title: 'ADMIN', icon: DashboardIcon },
        {
          label: 'Users',
          path: '/users',
          title: 'USERS',
          icon: PermIdentityIcon,
        },
        {
          label: 'Agency',
          path: '/agency',
          title: 'AGENCY',
          icon: SupportAgent,
        },
        // { label: '', path: '', title: 'PROFILE', icon: ExitToAppIcon },
      ],
      brand: [
        { label: 'Dashboard', path: '/', title: 'ADMIN', icon: DashboardIcon },
        { label: 'Brands', path: '/brands', title: 'BRANDS', icon: AdjustIcon },
        {
          label: 'Users',
          path: '/users',
          title: 'USERS',
          icon: PermIdentityIcon,
        },
        // { label: '', path: '', title: 'PROFILE', icon: ExitToAppIcon },
      ],
    };

    // Get the menu items based on the user's role
    const userMenuItems = menuItems[user.userType] || [];

    // Render the menu items
    return userMenuItems.map((item, index) => (
      <React.Fragment key={index}>
        {item.title && <p className="title">{item.title}</p>}
        <Link to={item.path} style={{ textDecoration: 'none' }}>
          <li>
            {item.icon && <item.icon className="icon" />}
            <span>{item.label && item.label}</span>
            {item.title === 'PROFILE' && (
              <span onClick={() => dispatch(deleteUser())}>Logout</span>
            )}
          </li>
        </Link>
      </React.Fragment>
    ));
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: 'none' }}>
          <img src={logo} alt="" className="logoimage" />
        </Link>
      </div>
      <div className="center">
        <ul>
          {/* <p className="title">MAIN</p>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link> */}
          {getMenuItems()}
          <Link to='' style={{ textDecoration: 'none' }}>
            <li>
              <ExitToAppIcon className="icon" />
              <span onClick={() => dispatch(deleteUser())}>Logout</span>
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
