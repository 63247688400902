import "./featuredpodcastersdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { podcastersInAgencyColumns } from "../../datatablesource";
import { useState, useEffect } from "react";
import {getEndpoint, saveEndpoint, fetchPodcastData, uploadPodcasterImage, updateEndpoint} from '../../utils/services';
import { FEATURED_PODCASTERS } from '../../src-constants/Urls'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { updateError, generateRandom, isValidURL, isValidImageURL, isValidField } from "../../src-constants/Functions";
import { useSelector } from "react-redux";
import { CANCEL_TEXT, PODCAST_AUTHOR_TEXT, PODCAST_DESCRIPTION_TEXT, PODCAST_OWNERNAME_TEXT, PODCAST_TITLE_TEXT } from "../../src-constants/Constants";

const FeaturedPodcasterDatable = () => {
  const token = useSelector(state => state.user.token);
  const initialState = {firstName: "", lastName: "", email: "", feedUrl: "", split: ""}
  const [podcastURL, setPodcastURL] = useState("");
  const [fetchedPodcastersData, setFetchedPodcastersData] = useState([{_id: "", title: "", description: "", image: "", url: "", author: "", ownerName: "", imagedisplay: "", authorImage:""}]);
  const [podcasterData, setPodcasterData] = useState([initialState]);
  const {podcastTitle, podcastDescription, podcastImageURL, podcastAuthor, podcastOwnerName} = podcasterData;
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const [podcasterId, setPodcasterId] = useState('');
  const [isUpdating, setIsUpdating] = useState(false)
  const [selectedFile, setSelectedFile] = useState([]);
  const [profileImageKey, setProfileImageKey] = useState("");
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false)

  //get all podcasters in agencies code to checked and added here
  useEffect(() => {
    getEndpoint(token, FEATURED_PODCASTERS)
    .then(response => {
      setFetchedPodcastersData(response.data.response)
    })
    .catch(error => {
      return updateError("Unable to retrieve the data!!", setError);
    });
  }, []);

  const handleOnChangeText = (value, fieldName) => {
    setPodcasterData({...podcasterData, [fieldName]: value});
  };

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    setPodcasterData(initialState);
    setPodcastURL("");
    setIsUploaded(false);
    setProfileImageKey("");
    setSelectedFile([]);
  };

  const isValidForm = () => {
    if (!isValidURL(podcastURL))
      return updateError('Invalid Podcast URL!', setError);
    return true;
  };

  const fetchDetails = (event) => {
    event.preventDefault();
    if (isValidForm()) {
      fetchPodcastData(token, podcastURL)
      .then(response => {
        setPodcasterData({
          podcastTitle: response.data.Response.title, 
          podcastDescription: response.data.Response.description, 
          podcastImageURL: response.data.Response.podcastImage, 
          podcastAuthor: response.data.Response.itunesInfo.author, 
          podcastOwnerName: response.data.Response.itunesInfo.owner.name,
        })
      })
      .catch(error => {
        initialState();
        return updateError('No podcast data exists for URL!', setError);
      });
    }
  }

  const isValidFormAdd = () => {
    if(isUploaded){
    if (!isValidURL(podcastURL))
      return updateError('Invalid Podcast URL!', setError);
    if ( isValidField(podcastTitle) || isValidField(podcastDescription)) 
      return updateError('Details Missing!!!', setError)
    if (!isValidImageURL(podcastImageURL))
      return updateError('Podcast Image URL is missing or incorrect!', setError);
    // if (!isValidURL(podcasterImageUrl))
    //   return updateError('Podcaster Image URL is missing or incorrect!', setError);
    return true;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValidFormAdd()) {
      const addFeaturedPodcastersPayload = {
        author: podcastAuthor,
        authorImageKey: profileImageKey,
        title: podcastTitle,
        description: podcastDescription,
        image: podcastImageURL,
        ownerName: podcastOwnerName,
        url: podcastURL,
      }
      saveEndpoint(token, FEATURED_PODCASTERS, addFeaturedPodcastersPayload)
      .then((response) => {
        setIsOpen(false);
        setPodcasterData(initialState);
        setPodcastURL("");
        setIsUploaded(false);
        setProfileImageKey("");
        setSelectedFile([]);
        // setPodcasterImageUrl("");
        getEndpoint(token, FEATURED_PODCASTERS).then((res) => {setFetchedPodcastersData(res.data.Response)}).catch(error => {return updateError("Unable to retrieve the data!!", setError);});
      })
      .catch(error => {
        return updateError("Unable to add pocaster!!", setError);
      });
    }
  }

  const handleOpenUpdate = (params) => {
    setIsUpdating(true)
    setIsOpen(true)
    setPodcasterId(params.row._id)
    setPodcasterData({
      podcastTitle: params.row.title, 
      podcastDescription: params.row.description, 
      podcastImageURL: params.row.image, 
      podcastAuthor: params.row.author, 
      podcastOwnerName: params.row.ownerName, 
    })
    setPodcastURL(params.row.url);
    setProfileImageKey(params.row.authorImageKey);
  };
  const handleCloseUpdate = () => {
    setIsUpdating(false)
    setIsOpen(false);
    setPodcasterData(initialState);
    setPodcastURL("");
    setProfileImageKey("");
  };
  
  const handleDeletePodcasters = (params) => {
    const deleteFeaturedPodcasterPayload = {
      author: params.row.author,
      authorImageKey: params.row.authorImageKey,
      title: params.row.title,
      description: params.row.description,
      image: params.row.image,
      ownerName: params.row.ownerName,
      url: params.row.url,
      status: "Inactive",
    }
    updateEndpoint(token, FEATURED_PODCASTERS, params.row._id, deleteFeaturedPodcasterPayload)
    .then((response) => {
      getEndpoint(token, FEATURED_PODCASTERS).then((res) => {setFetchedPodcastersData(res.data.response)}).catch(error => {return updateError("Unable to retrieve the data!!", setError);});
    })
    .catch((error) => {
      return updateError("Unable to delete podcaster!!", setError);
    });
  };

  const handleSubmitUpdate = (event) => {
    event.preventDefault();
    if (isValidForm()) {
      const updateFeaturedPodcastersPayload = {
        author: podcastAuthor,
        authorImageKey: profileImageKey,
        title: podcastTitle,
        description: podcastDescription,
        image: podcastImageURL,
        ownerName: podcastOwnerName,
        url: podcastURL,
        status: "Active",
      }
      updateEndpoint(token, FEATURED_PODCASTERS, podcasterId, updateFeaturedPodcastersPayload)
      .then((response) => {
        setIsUpdating(false)
        setIsOpen(false);
        setPodcasterData(initialState);
        setPodcastURL("");
        setProfileImageKey("");
        getEndpoint(token, FEATURED_PODCASTERS).then((res) => {setFetchedPodcastersData(res.data.response)}).catch(error => {return updateError("Unable to retrieve the data!!", setError);});
      })
      .catch((error) => {
        return updateError("Unable to update podcaster!!", setError);
      });
    }
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
              <div 
              onClick={() => {handleOpenUpdate(params)}} 
              className="viewButton">Update</div>
            <div
              className="deleteButton"
              onClick={() => {
                handleDeletePodcasters(params)
              }}
            >
              Deactivate
            </div>
          </div>
        );
      },
    },
  ];

 
  // On file select (from the pop up)
  const onFileChange = event => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  // On file upload (click the upload button)
  const onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();
   
    // Update the formData object
    formData.append("image", selectedFile);
   
    // Request made to the backend api
    // Send formData object
    setIsLoadingUpload(true);
    uploadPodcasterImage(token, formData)
    .then(response => {
      setProfileImageKey(response.data.Response.profileImg);
      setIsUploaded(true);
      setIsLoadingUpload(false);
    })
    .catch(error => {
      setIsLoadingUpload(false);
      return updateError('Please select an image to upload!', setError);
    });
  };

  return (
    <>
    <div className="datatable">
      {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
      <div className="datatableTitle">
        Podcasters (Featured)
        <Button onClick={handleOpen} className="link">Add Podcasters</Button>
      </div>
      <DataGrid
        className="datagrid"
        rows={fetchedPodcastersData}
        columns={podcastersInAgencyColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) =>  generateRandom()}
      />
    </div>
    
    <Modal
    open={isOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box className="boxStyle">
    {error ? <div className="errorMsg">{error}</div> : <div className="errorMsg"></div>}
    <div className="addTitle">{isUpdating ? 'Update' : 'Add'} Podcaster</div>
      <form onSubmit={isUpdating ? handleSubmitUpdate : handleSubmit}>  
        
        {!isUpdating ?

        <>
        <div className="formInput">
          <label>Podcaster Image</label>
          <input type="file" onChange={onFileChange} />
          { isUploaded ?
          <></>
          :
          <div className="buttonsContainer">
          <button onClick={onFileUpload}>
          {!isLoadingUpload ?
            <div>Upload</div>
          :      
            <div className="spinner"></div>
          }  
          </button>
          <div onClick={handleClose} className="closeText">{CANCEL_TEXT}</div>
          </div>
          }
        </div>
        
        { isUploaded ?
        <>
        <div className="formInput">
          <label>Podcast URL</label>
          <input type="text" placeholder="URL" name="url" value={podcastURL || ""} onChange={(event) => {setPodcastURL(event.target.value)}}/>
        </div>
        
        <div className="buttonsContainer">
        <button onClick={fetchDetails}>Fetch</button>
        </div>

        <div>
        <div className="formInput">
          <label>{PODCAST_TITLE_TEXT}</label>
          <input type="text" placeholder="title" name="title" value={podcastTitle || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'podcastTitle')}}/>
        </div>

        <div className="formInput">
          <label>{PODCAST_DESCRIPTION_TEXT}</label>
          <input type="text" placeholder="description" name="description" value={podcastDescription || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'podcastDescription')}}/>
        </div>

        <div className="formInput">
          <label>{PODCAST_AUTHOR_TEXT}</label>
          <input type="text" placeholder="author" name="author" value={podcastAuthor || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'podcastAuthor')}}/>
        </div>

        <div className="formInput">
          <label>Image URL</label>
          <input type="text" placeholder="image url" name="imageurl" value={podcastImageURL || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'podcastImageURL')}}/>
        </div>

        <div className="formInput">
          <label>{PODCAST_OWNERNAME_TEXT}</label>
          <input type="text" placeholder="owner name" name="ownername" value={podcastOwnerName || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'podcastOwnerName')}}/>
        </div>

        <div className="buttonsContainer">
        <button>ADD</button>
        <div onClick={handleClose} className="closeText">{CANCEL_TEXT}</div>
        </div>

        </div>
        </>
        :<></>}
        </>

        :

        <>
        <div className="formInput">
          <label>{PODCAST_TITLE_TEXT}</label>
          <input type="text" placeholder="title" name="title" value={podcastTitle || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'podcastTitle')}}/>
        </div>

        <div className="formInput">
          <label>{PODCAST_DESCRIPTION_TEXT}</label>
          <input type="text" placeholder="description" name="description" value={podcastDescription || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'podcastDescription')}}/>
        </div>

        <div className="formInput">
          <label>{PODCAST_AUTHOR_TEXT}</label>
          <input type="text" placeholder="author" name="author" value={podcastAuthor || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'podcastAuthor')}}/>
        </div>

        <div className="formInput">
          <label>Image URL</label>
          <input type="text" placeholder="image url" name="imageurl" value={podcastImageURL || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'podcastImageURL')}}/>
        </div>

        <div className="formInput">
          <label>{PODCAST_OWNERNAME_TEXT}</label>
          <input type="text" placeholder="owner name" name="ownername" value={podcastOwnerName || ""} onChange={(event) => {handleOnChangeText(event.target.value, 'podcastOwnerName')}}/>
        </div>

        <div className="buttonsContainer">
        <button>UPDATE</button>
        <div onClick={handleCloseUpdate} className="closeText">{CANCEL_TEXT}</div>
        </div>

        </>

        }

      </form>
    </Box>
    </Modal>
    </>
  );
};

export default FeaturedPodcasterDatable;
