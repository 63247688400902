import "./podcastgoldbalance.scss";
import { useSelector } from "react-redux";
import {getEndpoint} from '../../utils/services';
import { useState, useEffect } from "react";

const PodcastGoldBalance = ({balanceInGrams,balanceInRupees}) => {
  const user = useSelector(state => state.user);
  const [walletBalance, setWalletBalance] = useState("");

  return (
    <div className="widgetGold">
      {/* {!isLoading ? */}
      <div className="left">
        <span className="title">Total Gold Balance</span>
        <span className="goldweight">
        {balanceInGrams} gms
        </span>
        <span className="goldamount">₹ {balanceInRupees}</span>
      </div>
      {/* :
      <div className="spinner"></div>
      } */}
    </div>
  );
};

export default PodcastGoldBalance;
