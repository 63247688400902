export const isValidURL = value => {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    return urlPattern.test(value);
  };

export const isValidImageURL = (url) => {
  // const imagePattern = /\.(jpg|jpeg|png|gif)$/i;
  // return imagePattern.test(url);
  return true
};

export const updateError = (error, stateUpdater) => {
    stateUpdater(error);
    setTimeout(() => {
      stateUpdater('');
    }, 2500);
  };  

export function generateRandom() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }  

export const isValidEmail = value => {
    const regX =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regX.test(value);
  };

export const handleChangeDateTime = (datetime) => {
    var eventDate = datetime.$d.getDate(); //Current Date
    var eventMonth = datetime.$d.getMonth() + 1; //Current Month
    var eventYear = datetime.$d.getFullYear(); //Current Year
    var eventHours = datetime.$d.getHours(); //Current Hours
    var eventMin = datetime.$d.getMinutes(); //Current Minutes
    var eventSec = datetime.$d.getSeconds(); //Current Seconds
    const eventDateAndTime = ((eventMonth < 10 ?  `0${eventMonth}` : eventMonth) + '-' + (eventDate < 10 ?  `0${eventDate}` : eventDate) + '-' + eventYear + ' ' + (eventHours < 10 ?  `0${eventHours}` : eventHours) + ':' + (eventMin < 10 ?  `0${eventMin}` : eventMin) + ':' + (eventSec < 10 ?  `0${eventSec}` : eventSec)) 
    return eventDateAndTime;
  };
  
export const isValidObjField = obj => {
  return Object.values(obj).every(value => value.trim());
};

export const isValidField = value => {
  return (value === undefined || value==="" || /^\s*$/.test(value))
};

export const isValidPincode = value => {
  return (!isNaN(value))
};
